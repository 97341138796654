import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StoresViewModel } from 'common/models';
import { StoreIncentivesViewModel } from 'common/models/StoreTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'Stores';

interface IStorestate {
  submitStatus: OperationStatus;
  Stores: StoresViewModel[];
  StoreIncentivesViewModel: StoreIncentivesViewModel[];
}

const initialState: IStorestate = {
  submitStatus: OperationStatus.idle,
  Stores: [],
  StoreIncentivesViewModel: [],
};

export const StoresSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetStores: (state, action: PayloadAction<StoresViewModel[]>) => {
      state.Stores = action.payload;
    },
    SetStoreIncentivesViewModel: (state, action: PayloadAction<StoreIncentivesViewModel[]>) => {
      state.StoreIncentivesViewModel = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    submitStatusSucceededNoPayload: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: StoresActions } = StoresSlice;

export default StoresSlice.reducer;
