import { APIResponseCode } from 'common/constants';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { ReceiverModel } from 'common/models/BookingTypes';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { ReceiverActions } from '../store/ReceiverSlice';
import { HandleErrorMessage } from '@features/Common';
import { commonAction } from '@features/Common/store/commonSlice';

export const GetAllReceiver = async (data: string | undefined): Promise<ReceiverModel[]> => {
  try {
    const response = await PostData<APIResponse>(KFSEndpoints.RECEIVER_DETAILS_GET_ALL, data);
    new Validator(response).NotNull();
    new Validator(response.parsedBody?.Status).NotNull();
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    // Assuming your API response has a property called 'results' that contains the list of senders
    const receiver: ReceiverModel[] = response.parsedBody?.Results || [];
    return receiver;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // You may want to handle the error differently based on your application's needs
  }
};

export const GetReceiverById = (id: string) => {
  return async function GetReceiverByIdThunk(dispatch) {
    const methodName = 'GetReceiverById';
    try {
      dispatch(ReceiverActions.submitStatus());

      const response = await PostData<APIResponse>(KFSEndpoints.RECEIVER_GET_BY_ID, id);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      new Validator(response.parsedBody?.Results).NotNull();
      const data: ReceiverModel = response.parsedBody?.Results;
      dispatch(ReceiverActions.submitStatusSucceeded(data));
    } catch (e) {
      // const errorMessage = HandleErrorMessage(methodName, e);
      // dispatch(ariTestCategoryActions.loadRejected(errorMessage));
    }
  };
};

export const UpdateReceiver = (receiver: ReceiverModel) => {
  return async function UpdateReceiverThunk(dispatch) {
    const methodName = 'UpdateReceiver';
    // console.log('UpdateSender assign: ' + JSON.stringify(sender));
    try {
      dispatch(ReceiverActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.RECEIVER_UPDATE, receiver);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(ReceiverActions.SetSelectedId(response.parsedBody?.Results));
      dispatch(commonAction.SetGlobalReceiverId(response.parsedBody?.Results));
      dispatch(ReceiverActions.submitStatusSucceeded(undefined));
    } catch (e) {
      const errorMessage = HandleErrorMessage(methodName, e);
      dispatch(ReceiverActions.submitStatusRejected(errorMessage));
    }
  };
};

export const AddReceiver = (receiver: ReceiverModel) => {
  return async function AddReceiverThunk(dispatch) {
    const methodName = 'AddReceiver';
    // console.log('UpdateSender assign: ' + JSON.stringify(sender));
    try {
      dispatch(ReceiverActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.RECEIVER_SAVE, receiver);

      new Validator(response).NotNull();
      new Validator(response.parsedBody).NotNull();
      new Validator(response.parsedBody?.Status).NotNull().IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(ReceiverActions.SetSelectedId(response.parsedBody?.Results));
      dispatch(commonAction.SetGlobalReceiverId(response.parsedBody?.Results));
      dispatch(ReceiverActions.submitStatusSucceeded(undefined));
    } catch (e) {
      // const errorMessage = HandleErrorMessage(methodName, e);
      // dispatch(ariTestCategoryActions.loadRejected(errorMessage));
    }
  };
};
