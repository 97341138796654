import { Button, Card, Checkbox, Col, Form, Modal, Row, Select, Table, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteBooking,
  GetRemarks,
  GetTransactionById,
  LoadToContainer,
  SaveRemarks,
  SendOR,
} from '../business/TransactionBusiness';
import { TransactionSelector } from '../store/TransactionSelector';
import { Link, useHistory } from 'react-router-dom';
import { DestinationCodeCodeList } from 'common/code/DestinationCode';
import { Constants, PaymentStatus, Roles } from 'common/constants';
import { formatTwoDecimalPlaces } from 'common/utils/utility';
import { TransactionActions } from '../store/TransactionSlice';
import dayjs from 'dayjs';
import { TransStatusDisplayViewModel } from 'common/models/BookingTypes';
import { AccountSelector } from '@features/Account';
import { ReceiverActions } from '@features/Receiver';
import { getPaymentTypeNameByCode, PaymentTypeCodeList } from 'common/code/BookingCode';
const { Text } = Typography;
import { ExclamationCircleFilled } from '@ant-design/icons';
import { OperationStatus } from 'store/rootTypes';
import '@features/Transaction/Transaction.css';
import { commonAction } from '@features/Common/store/commonSlice';
import { PATHS } from '@routing/routes';
import TextArea from 'antd/es/input/TextArea';
import { RemarksResponse } from 'common/models/RemarksTypes';
export const SummaryTransaction = () => {
  const [form] = Form.useForm(); // Initialize the form
  const dispatch = useDispatch();
  const history = useHistory();
  const accountState = useSelector(AccountSelector);
  const transactionState = useSelector(TransactionSelector);
  let data: TransStatusDisplayViewModel[] = transactionState.TransactionSummary?.TransStatusDisplay || [];
  let remarksData: RemarksResponse[] = transactionState.TransactionRemarks || [];
  useEffect(() => {
    const fetchData = async () => {
      if (transactionState.SelectedTransId) {
        await dispatch(GetTransactionById(transactionState.SelectedTransId));
        data = transactionState.TransactionSummary?.TransStatusDisplay || [];

        await dispatch(GetRemarks(transactionState.SelectedTransId));
        remarksData = transactionState.TransactionRemarks || [];
      }
    };
    dispatch(TransactionActions.SetFromUpdateLinkFalse());
    fetchData();
  }, [transactionState?.SelectedTransId]);

  function checkCodeExist(data: TransStatusDisplayViewModel[], code: string): boolean {
    return !!data && data.some((item) => item.Code === code);
  }
  const onFinish = async (values: any) => {
    dispatch(TransactionActions.SetFromUpdateLinkFalse());
    if (transactionState.TransactionSummary?.Receiver.Id == Constants.NoSelectedReceiver) {
      message.error('Please select or update receiver first.');
      return;
    }
    if (transactionState.Invoice?.Status != PaymentStatus.Paid) {
      history.push('/invoice');
    } else if (transactionState.TransactionSummary?.Barcode.length != Number(transactionState.Invoice?.UnitCount)) {
      history.push('/booking/barcode');
    } else if (!transactionState.TransactionSummary?.Signature) {
      history.push('/booking/signature');
    }
  };

  const onBack = () => {
    dispatch(TransactionActions.SetSelectedTransId(undefined));
    history.push('/transaction');
  };

  const handleUpdateClick = (userId?: string, transactionId?: string, path?: string, senderId?: string) => {
    if (userId && transactionId && path && senderId) {
      dispatch(TransactionActions.SetFromUpdateLink());
      dispatch(commonAction.SetGlobalSenderUserId(userId));
      dispatch(commonAction.SetGlobalTransactionId(transactionId));
      dispatch(commonAction.SetGlobalSenderId(senderId));
      history.push(path);
    }
  };

  const handleUpdateClickReceiver = (userId?: string, transactionId?: string, path?: string, receiverId?: string) => {
    if (userId && transactionId && path && receiverId) {
      dispatch(commonAction.SetGlobalReceiverUserId(userId));
      dispatch(commonAction.SetGlobalTransactionId(transactionId));
      dispatch(commonAction.SetGlobalReceiverId(receiverId));
      history.push(path);
    }
  };

  const handleUpdateClickBox = () => {
    dispatch(TransactionActions.SetFromUpdateLink());
  };

  const handleUpdateNoSelectedReceiver = () => {
    dispatch(ReceiverActions.SetIsReceiverNoSelected(true));
    dispatch(ReceiverActions.SetSelectedId(Constants.NoSelectedReceiver));
  };

  const [modalVisible, setModalVisible] = useState(false);

  const handleViewTermsClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const hideUpdate = () => {
    if (accountState.RoleName != Roles.Customer) {
      return true;
    }

    if (
      transactionState?.TransactionSummary?.TransactionStatus?.length &&
      transactionState.TransactionSummary.TransactionStatus.length <= 2
    ) {
      return true;
    }
    return false;
  };
  const columns = [
    // {
    //   title: 'Date',
    //   dataIndex: 'CreatedDate',
    //   key: 'CreatedDate',
    // },
    {
      title: 'Tracking details',
      dataIndex: 'Description',
      key: 'Description',
      render: (text, record) => {
        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </>
        );
      },
    },
  ];

  const { confirm } = Modal;

  const showConfirm = () => {
    //Shift yes or no to satisfy the req. will refactor next time
    confirm({
      title: 'Do you want to delete this booking?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        if (transactionState.SelectedTransId) {
          await dispatch(DeleteBooking(transactionState.SelectedTransId));
          await dispatch(TransactionActions.SetSelectedTransId(undefined));

          if (accountState.RoleName == Roles.Customer) {
            history.push('/booking/listing');
          } else {
            history.push('/transaction');
          }
        }
      },
      async onOk() {
        //conso
      },
    });
  };

  const SendOrClick = async () => {
    if (transactionState.SelectedTransId) {
      await dispatch(SendOR(transactionState.SelectedTransId));
    }
  };

  const [remarks, setRemarks] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the input
    if (!remarks) {
      return;
    }

    if (transactionState.SelectedTransId) {
      await dispatch(
        SaveRemarks({
          TransactionsId: transactionState.SelectedTransId,
          Details: remarks,
        })
      );
    }

    setRemarks('');
    form.setFieldsValue({ Remarks: '' });
  };

  const handleContainerChange = async (value = 'removed') => {
    if (!value) {
      value = 'removed';
    }
    if (transactionState.SelectedTransId && value) {
      await dispatch(
        LoadToContainer({
          TransactionId: transactionState.SelectedTransId,
          ContainerId: value,
        })
      );
    }
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        <h1>Transaction summary</h1>
      </Row>
      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text className="">Transaction</Text>
                </Col>
                {data.length >= 1 && data.length <= 3 && accountState.RoleName !== Roles.Customer && (
                  <Col span={12} className="right-text">
                    <a className="normal-font" onClick={showConfirm}>
                      {Constants.BTN_CANCEL_BOOKING}
                    </a>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>Reference no: {transactionState.TransactionSummary?.Transactions.RefNo}</Text>
                    <br />
                    {data[data.length - 1]?.Code === 'ITRA' ? (
                      <>
                        <Text>Status: Received - with KFS agent</Text>
                      </>
                    ) : (
                      <Text>Status: {data[data.length - 1]?.Status}</Text>
                    )}
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text className="">Sender</Text>
                </Col>
                {hideUpdate() && (
                  <Col span={12} className="right-text">
                    <a
                      // to="/booking/sender"
                      className="normal-font"
                      onClick={() =>
                        handleUpdateClick(
                          transactionState.TransactionSummary?.Sender.UserId,
                          transactionState.TransactionSummary?.Transactions.Id,
                          PATHS.SenderListingBooking.path,
                          transactionState.TransactionSummary?.Sender.Id
                        )
                      }
                    >
                      Update
                    </a>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.Sender.GivenName}{' '}
                      {transactionState.TransactionSummary?.Sender.Surname}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.Sender.Address},{' '}
                      {transactionState.TransactionSummary?.Sender.Suburb},{' '}
                      {transactionState.TransactionSummary?.Sender.State},{' '}
                      {transactionState.TransactionSummary?.Sender.PostalCode}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>{transactionState.TransactionSummary?.Sender.Email}</Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.Sender.MobileCode}{' '}
                      {transactionState.TransactionSummary?.Sender.ContactNo}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>Receiver</Text>
                </Col>
                {transactionState.TransactionSummary?.Receiver.Id !== Constants.NoSelectedReceiver ? (
                  hideUpdate() && (
                    <Col span={12} className="right-text">
                      <a
                        className="normal-font"
                        onClick={() =>
                          handleUpdateClickReceiver(
                            transactionState.TransactionSummary?.Receiver.UserId,
                            transactionState.TransactionSummary?.Transactions.Id,
                            PATHS.ReceiverListingBooking.path,
                            transactionState.TransactionSummary?.Receiver.Id
                          )
                        }
                      >
                        Update
                      </a>
                    </Col>
                  )
                ) : (
                  <Col span={12} className="right-text">
                    <Link to="/receiver/listing" className="normal-font" onClick={handleUpdateNoSelectedReceiver}>
                      Update
                    </Link>
                  </Col>
                )}

                {transactionState.TransactionSummary?.Receiver.Id != Constants.NoSelectedReceiver ? (
                  <Card className="card-custom">
                    <Col span={24}>
                      <Text>
                        {transactionState.TransactionSummary?.Receiver?.GivenName}{' '}
                        {transactionState.TransactionSummary?.Receiver?.Surname}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {transactionState.TransactionSummary?.Receiver?.Address},{' '}
                        {transactionState.TransactionSummary?.Receiver?.City},{' '}
                        {transactionState.TransactionSummary?.Receiver?.Province},{' '}
                        {transactionState.TransactionSummary?.Receiver?.PostalCode}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {transactionState.TransactionSummary?.Receiver?.MobileCode}
                        {transactionState.TransactionSummary?.Receiver?.ContactNo}
                      </Text>
                    </Col>
                  </Card>
                ) : (
                  <Card className="card-custom">
                    <Col span={24}>
                      <Text>{Constants.NoSelectedReceiverText}</Text>
                    </Col>
                  </Card>
                )}
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>What’s in the box</Text>
                </Col>
                {hideUpdate() && (
                  <Col span={12} className="right-text">
                    <Link to="/booking/boxcontent" className="normal-font" onClick={handleUpdateClickBox}>
                      Update
                    </Link>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.BoxContent?.Details &&
                        transactionState.TransactionSummary?.BoxContent.Details?.length > 0 && (
                          <>
                            {transactionState.TransactionSummary?.BoxContent.Details[0]}
                            {transactionState.TransactionSummary?.BoxContent.Details?.slice(1).map(
                              (detail) => `, ${detail}`
                            )}
                          </>
                        )}
                      {transactionState.TransactionSummary?.BoxContent?.Details != undefined &&
                        transactionState.TransactionSummary?.BoxContent?.Details?.length > 0 &&
                        transactionState.TransactionSummary?.BoxContent?.Others?.length > 0 && <br />}
                      {transactionState.TransactionSummary?.BoxContent?.Others}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>Pick up and box details</Text>
                </Col>
                {hideUpdate() && (
                  <Col span={12} className="right-text">
                    <Link to="/booking/boxdetails" className="normal-font" onClick={handleUpdateClickBox}>
                      Update
                    </Link>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.BoxDetails?.Date && (
                        <>
                          Box pickup date:{' '}
                          {dayjs(transactionState.TransactionSummary?.BoxDetails.Date).format('YYYY-MM-DD')}
                          <br />
                        </>
                      )}
                      {transactionState.TransactionSummary?.BoxDetails?.NoOfBox}{' '}
                      {transactionState.TransactionSummary?.BoxDetails?.NoOfBox &&
                      transactionState.TransactionSummary?.BoxDetails?.NoOfBox > 1
                        ? 'boxes'
                        : 'box'}{' '}
                      {/* going to{' '} */}
                      {transactionState.TransactionSummary?.BoxDetails?.Remarks && (
                        <>
                          <br />
                          {transactionState.TransactionSummary?.BoxDetails.Remarks}
                        </>
                      )}
                      {transactionState.TransactionSummary?.BoxDetails?.StoreId &&
                        transactionState.TransactionSummary?.BoxDetails?.StoreName &&
                        accountState.RoleName != Roles.Customer && (
                          <>
                            <br />
                            Store: {transactionState.TransactionSummary?.BoxDetails?.StoreName}
                            <br />
                          </>
                        )}
                      {transactionState.TransactionSummary?.BoxDetails?.PickupBy &&
                        transactionState.TransactionSummary?.BoxDetails?.PickupName &&
                        accountState.RoleName != Roles.Customer && (
                          <>
                            <br />
                            Pickup by: {transactionState.TransactionSummary?.BoxDetails?.PickupName}
                          </>
                        )}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            {transactionState.TransactionSummary?.IncentiveSummaryDisplay && (
              <Form.Item>
                <Row>
                  <Col span={12}>
                    <Text>Your perks</Text>
                  </Col>
                  <Card className="card-custom">
                    <Col span={24}>
                      <Text>
                        {transactionState.TransactionSummary?.IncentiveSummaryDisplay.SignupBonus} <br />
                        {transactionState.TransactionSummary?.IncentiveSummaryDisplay.RefereeBonus}{' '}
                      </Text>
                    </Col>
                  </Card>
                </Row>
              </Form.Item>
            )}
            {transactionState.TransactionSummary?.Invoice?.Type !== null &&
              transactionState.TransactionSummary?.Invoice?.Type !== undefined &&
              transactionState.TransactionSummary?.Invoice?.Type.trim() !== '' && (
                <Form.Item>
                  <Row>
                    <Col span={12}>
                      <Text>Invoice</Text>
                    </Col>
                    <Col span={12} className="right-text">
                      {accountState.RoleName != Roles.Customer && (
                        <a
                          // to="/invoice"
                          className="normal-font"
                          onClick={() =>
                            handleUpdateClick(
                              transactionState.TransactionSummary?.Sender.UserId,
                              transactionState.TransactionSummary?.Transactions.Id,
                              PATHS.Invoice.path,
                              transactionState.TransactionSummary?.Sender.Id
                            )
                          }
                        >
                          Update
                        </a>
                      )}
                    </Col>
                    <Modal
                      title="Invoice"
                      visible={modalVisible}
                      onCancel={handleCloseModal}
                      footer={[
                        <Button key="agree" type="primary" onClick={handleCloseModal}>
                          Close
                        </Button>,
                      ]}
                    >
                      Type: {transactionState.TransactionSummary?.Invoice?.Type == 'A' ? 'Air ' : 'Sea'} <br />
                      Going to:{' '}
                      {
                        DestinationCodeCodeList.find(
                          (destination) => destination.code === transactionState.Invoice?.Destination
                        )?.name
                      }
                      <br />
                      Status:{' '}
                      {transactionState.TransactionSummary?.Invoice?.Status === PaymentStatus.Paid
                        ? 'Paid'
                        : transactionState.TransactionSummary?.Invoice?.Status === PaymentStatus.Unpaid
                        ? 'Unpaid'
                        : 'Partially Paid'}{' '}
                      <br />
                      Unit count: {transactionState.TransactionSummary?.Invoice?.UnitCount} <br />
                      Basic charge: {formatTwoDecimalPlaces(
                        transactionState.TransactionSummary?.Invoice?.BasicCharge
                      )}{' '}
                      <br />
                      {transactionState.TransactionSummary?.Invoice?.Type === 'A' && (
                        <>
                          Weight: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Weight)} <br />
                          Handling charge:{' '}
                          {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.ServiceCharge)} <br />
                          Area charge: {formatTwoDecimalPlaces(
                            transactionState.TransactionSummary?.Invoice?.KmCharge
                          )}{' '}
                          <br />
                        </>
                      )}
                      Other charges:{' '}
                      {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.OtherCharges)} <br />
                      Insurance charge:{' '}
                      {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.InsuranceCharge)} <br />
                      Deposit: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Deposit)} <br />
                      Bonus: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Bonus)} <br />
                      Discount: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Discount)} <br />
                      Total amount: {formatTwoDecimalPlaces(
                        transactionState.TransactionSummary?.Invoice?.TotalAmount
                      )}{' '}
                      <br />
                      Balance: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Balance)} <br />
                      Change: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Change)} <br />
                      Mode of payment:{' '}
                      {getPaymentTypeNameByCode(transactionState.TransactionSummary?.Invoice.PaymentMode)} <br />
                    </Modal>
                    <Card className="card-custom">
                      <Col span={24}>
                        <Text>Invoice No: {transactionState.TransactionSummary?.Invoice?.InvoiceNo} </Text>
                      </Col>
                      <Col span={24}>
                        <Text>
                          Status:{' '}
                          {transactionState.TransactionSummary?.Invoice?.Status === PaymentStatus.Paid
                            ? 'Paid'
                            : transactionState.TransactionSummary?.Invoice?.Status === PaymentStatus.Unpaid
                            ? 'Unpaid'
                            : 'Partially Paid'}
                        </Text>
                      </Col>
                      {accountState.RoleName != Roles.Customer && (
                        <Col span={24}>
                          <Text>
                            Type: {transactionState.TransactionSummary?.Invoice?.Type == 'A' ? 'Air ' : 'Sea'}{' '}
                          </Text>
                        </Col>
                      )}
                      <Col span={24}>
                        <Text>
                          Destination:{' '}
                          {
                            DestinationCodeCodeList.find(
                              (destination) => destination.code === transactionState.Invoice?.Destination
                            )?.name
                          }{' '}
                        </Text>
                      </Col>
                      {transactionState.TransactionSummary?.Invoice?.UnitCount && (
                        <Col span={24}>
                          <Text>Unit count: {transactionState.TransactionSummary?.Invoice?.UnitCount}</Text>
                        </Col>
                      )}
                      {transactionState.TransactionSummary?.Invoice?.Weight > 0 && (
                        <Col span={24}>
                          <Text>
                            Weight: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Weight)}
                          </Text>
                        </Col>
                      )}

                      {transactionState.TransactionSummary?.Invoice?.BasicCharge > 0 && (
                        <Col span={24}>
                          <Text>
                            Basic charge:{' '}
                            {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.BasicCharge)}
                          </Text>
                        </Col>
                      )}

                      {transactionState.TransactionSummary?.Invoice?.ServiceCharge > 0 && (
                        <Col span={24}>
                          <Text>
                            Handling charge:{' '}
                            {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.ServiceCharge)}
                          </Text>
                        </Col>
                      )}

                      {transactionState.TransactionSummary?.Invoice?.KmCharge > 0 && (
                        <Col span={24}>
                          <Text>
                            Area charge:{' '}
                            {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.KmCharge)}
                          </Text>
                        </Col>
                      )}
                      {transactionState.TransactionSummary?.Invoice?.OtherCharges > 0 && (
                        <Col span={24}>
                          <Text>
                            Other charges:{' '}
                            {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.OtherCharges)}
                          </Text>
                        </Col>
                      )}
                      {transactionState.TransactionSummary?.Invoice?.InsuranceCharge > 0 && (
                        <Col span={24}>
                          <Text>
                            Insurance charge:{' '}
                            {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.InsuranceCharge)}
                          </Text>
                        </Col>
                      )}
                      <Col span={24}>
                        <Text>
                          Total amount:{' '}
                          {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.TotalAmount)}{' '}
                        </Text>
                      </Col>
                      {transactionState.TransactionSummary?.Invoice?.Deposit > 0 && (
                        <Col span={24}>
                          <Text>
                            Deposit: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Deposit)}{' '}
                          </Text>
                        </Col>
                      )}
                      {transactionState.TransactionSummary?.Invoice?.Bonus !== undefined &&
                        transactionState.TransactionSummary?.Invoice?.Bonus > 0 && (
                          <Col span={24}>
                            <Text>
                              Bonus: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Bonus)}{' '}
                            </Text>
                          </Col>
                        )}
                      {transactionState.TransactionSummary?.Invoice?.Discount > 0 && (
                        <Col span={24}>
                          <Text>
                            Discount: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Discount)}{' '}
                          </Text>
                        </Col>
                      )}

                      <Col span={24}>
                        <Text>
                          Amount paid:{' '}
                          {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.AmountPaid)}{' '}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text>
                          Amount due: {formatTwoDecimalPlaces(transactionState.TransactionSummary?.Invoice?.Balance)}{' '}
                        </Text>
                      </Col>

                      {transactionState.TransactionSummary?.Invoice?.Notes && (
                        <Col span={24}>
                          <Text>Agent’s notes: {transactionState.TransactionSummary?.Invoice?.Notes}</Text>
                        </Col>
                      )}
                      {/* {accountState.RoleName !== Roles.Customer && transactionState.ORDateSend && (
                        <Col span={24}>
                          <Text>
                            <i> Receipt sent: {transactionState.ORDateSend}</i>
                          </Text>
                        </Col>
                      )} */}
                      <br />
                      {accountState.RoleName != Roles.Customer && (
                        <Col span={24}>
                          {transactionState.ORDateSend && (
                            <Text>
                              <i className="textColor">Receipt sent: {transactionState.ORDateSend}</i>
                            </Text>
                          )}
                          <a onClick={SendOrClick} className="floatRight">
                            <u> Send receipt</u>
                          </a>
                        </Col>
                      )}
                    </Card>
                  </Row>
                </Form.Item>
              )}{' '}
            {Number(transactionState.TransactionSummary?.Barcode.length) > 0 && (
              <Form.Item>
                <Row>
                  <Col span={12}>
                    <Text>Barcode</Text>
                  </Col>
                  <Col span={12} className="right-text">
                    {accountState.RoleName != Roles.Customer && (
                      <Link to="/booking/barcode" onClick={handleUpdateClickBox}>
                        Update
                      </Link>
                    )}
                  </Col>
                  <Card className="card-custom">
                    <Col span={24}>
                      {transactionState.TransactionSummary?.Barcode?.map((barcode, index) => (
                        <div key={index}>
                          <Text>
                            {barcode.Abbreviations1}
                            {barcode.Number}
                            {barcode.Abbreviations2}
                          </Text>
                          {/* Add other properties as needed */}
                        </div>
                      ))}
                    </Col>
                  </Card>
                </Row>
              </Form.Item>
            )}{' '}
            {transactionState.TransactionSummary?.Signature?.Sender != null && (
              <>
                <Form.Item className="mb-0">
                  <Row>
                    <Col span={12}>
                      <Text>Sender signature</Text>
                    </Col>
                    <Col span={12} className="right-text">
                      {accountState.RoleName != Roles.Customer &&
                        transactionState.TransactionSummary?.Barcode.length ===
                          Number(transactionState.Invoice?.UnitCount) && (
                          <Link to="/booking/signature" onClick={handleUpdateClickBox}>
                            Update
                          </Link>
                        )}
                    </Col>
                    <Card className="card-custom">
                      <Col span={24}>
                        <img
                          src={transactionState.TransactionSummary?.Signature?.Sender}
                          alt="Sender signature"
                          className="signatureRender"
                        />
                      </Col>
                    </Card>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Checkbox checked className="custom-checkbox-tC">
                    I agree to the Terms and conditions.
                  </Checkbox>
                </Form.Item>
              </>
            )}{' '}
            {accountState.RoleName != Roles.Customer && (
              <>
                <Form.Item className="mb-5">
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Add note" name="Remarks" className="mb-5">
                        <TextArea size="large" onChange={(e) => setRemarks(e.target.value)} value={remarks} />
                      </Form.Item>
                      <Button
                        className="nextSenderBtn3"
                        size="middle"
                        type="primary"
                        htmlType="button"
                        onClick={handleSubmit}
                      >
                        {Constants.BTN_ADD}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                {remarksData && remarksData.length > 0 && (
                  <Form.Item>
                    <Row>
                      <Col span={24}>
                        <label>Notes</label>
                        <Card className="card-custom lightgrayF9">
                          <Col span={24}>
                            {remarksData?.map((result, index) => (
                              <p
                                key={index}
                                dangerouslySetInnerHTML={{ __html: result.Details }}
                                className="mtp-0 mb-5"
                              ></p>
                            ))}
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </Form.Item>
                )}
              </>
            )}
            {transactionState.TransactionSummary?.Container &&
              transactionState.TransactionSummary?.Container.length > 0 &&
              accountState.RoleName == Roles.Admin && (
                <Form.Item
                  // initialValue={transactionState?.Invoice?.PaymentMode}
                  label="Container"
                  name="Container"
                  initialValue={transactionState.TransactionSummary?.Container.find((x) => x.IsSelected)?.Id || null}

                  // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={handleContainerChange}
                    disabled={transactionState.TransactionSummary.TransactionStatus.some((x) =>
                      ['OFD', 'DE', 'ITADP'].includes(x.Status)
                    )}
                  >
                    <Select key="" value=""></Select>
                    {transactionState.TransactionSummary?.Container.map((destination) => (
                      <Select.Option key={destination.Id} value={destination.Id}>
                        {destination.ContainerNo}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            <br />
            <Table className="table-summary" dataSource={data} columns={columns} pagination={false} />
            <br />
            {accountState.RoleName != Roles.Customer && (
              <div>
                {transactionState.Invoice?.Status !== PaymentStatus.Paid ||
                transactionState.TransactionSummary?.Barcode.length !== Number(transactionState.Invoice?.UnitCount) ||
                !transactionState.TransactionSummary?.Signature ? (
                  <>
                    <Button
                      // loading={bookingState.submitStatus === OperationStatus.pending}
                      className="nextSenderBtn"
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      {Constants.BTN_CONTINUE}
                    </Button>
                    <Button className="prevSenderBtn " size="large" type="default" onClick={() => onBack()}>
                      {Constants.BTN_BACK}
                    </Button>
                  </>
                ) : (
                  // Button component for anotherCondition
                  <>
                    <Button className="prevSenderBtn full-width " size="large" type="default" onClick={() => onBack()}>
                      {Constants.BTN_BACK}
                    </Button>{' '}
                  </>
                )}
                {/* checkCodeExist(data, 'ITADP') ? (
                  <>
                    <Button
                      // loading={bookingState.submitStatus === OperationStatus.pending}
                      className="nextSenderBtn"
                      size="large"
                      type="primary"
                      onClick={() => history.push(PATHS.Delivered.path)}
                    >
                      Update to deliver
                    </Button>
                    <Button className="prevSenderBtn " size="large" type="default" onClick={() => onBack()}>
                      {Constants.BTN_BACK}
                    </Button>
                  </>
                ) : (
                  // Button component for anotherCondition
                  <>
                    <Button className="prevSenderBtn full-width " size="large" type="default" onClick={() => onBack()}>
                      {Constants.BTN_BACK}
                    </Button>{' '}
                  </>
                )} */}
              </div>
            )}
            {accountState.RoleName == Roles.Customer && (
              <>
                {data.length === 1 && (
                  <Button
                    loading={transactionState.submitStatus === OperationStatus.pending}
                    className="prevSenderBtn2"
                    size="large"
                    type="default"
                    onClick={showConfirm}
                  >
                    {Constants.BTN_CANCEL_BOOKING}
                  </Button>
                )}{' '}
                <Button
                  className={`nextSenderBtn2 ${data.length > 1 ? 'full-width' : ''}`}
                  size="large"
                  type="primary"
                  onClick={() => history.push('/booking/listing')}
                >
                  {Constants.BTN_BACK}
                </Button>{' '}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
