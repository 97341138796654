import { Constants, Roles } from 'common/constants';
import { PATHS } from '@routing/routes';
import { useDispatch, useSelector } from 'react-redux';
import { SenderDetailsActions, SenderDetailsSelector } from '@features/SenderDetails';
import { useEffect } from 'react';
import SenderCommon from '@features/Common/component/SenderCommon';
import { EmployeeSelector } from '@features/Employee';
import { BookingSelector } from '@features/Booking';
import { AccountSelector } from '@features/Account';
import { commonSelector } from '@features/Common/store/commonSelector';

export const OrderBoxSender = () => {
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const employeeAgentState = useSelector(EmployeeSelector);
  const accountState = useSelector(AccountSelector);
  const bookingState = useSelector(BookingSelector);
  const commonState = useSelector(commonSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    // const fetchData = async () => {
    //   console.log(JSON.stringify(employeeAgentState.Employee));
    //   if (!employeeAgentState.Employee) {
    //     await dispatch(EmployeeGetAll());
    //   }
    // };
    // fetchData();
    if (
      senderDetailsState.SelectedId ||
      bookingState.FromSignupExistingUserId ||
      accountState.RoleName == Roles.Customer
    ) {
      dispatch(SenderDetailsActions.SetShowSenderForm(true));
    }
  }, []);

  const ShowEmailSearch = () => {
    if (accountState.RoleName == Roles.Customer) return false;

    if (commonState.GlobalSenderUserId) return false;

    if (
      senderDetailsState.SelectedId == undefined &&
      bookingState.FromSignupExistingUserId == undefined &&
      accountState.RoleName != Roles.Customer
    )
      return true;
  };

  return (
    <div className="App">
      <SenderCommon
        header={senderDetailsState.SelectedId == undefined ? 'Delivery address' : 'Delivery address'}
        showSenderForm={commonState.GlobalSenderUserId ? true : senderDetailsState.ShowSenderForm}
        onBack={
          senderDetailsState.SelectedId == undefined &&
          bookingState.FromSignupExistingUserId == undefined &&
          accountState.RoleName != Roles.Customer
            ? PATHS.Home.path
            : PATHS.SenderListingOrderBox.path
        }
        onNext={PATHS.OrderBoxAddress.path}
        senderModel={senderDetailsState.Sender}
        buttonNames={{
          prevButton: Constants.BTN_BACK,
          nextButton: Constants.BTN_CONTINUE,
        }}
        listingPath={PATHS.SenderListingOrderBox.path}
        showEmailSearch={ShowEmailSearch()}
      />
    </div>
  );
};
