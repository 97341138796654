import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { TrackingActions } from '../store/TrackingSlice';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { APIResponseCode } from 'common/constants';
import { Validator } from 'common/validators/validators';
import { message } from 'antd';
import {
  TrackingDetailsViewModel,
  TrackingManagementListResponse,
  TrackingStatusResponse,
} from 'common/models/TrackingTypes';

export const GetTrackingStatus = (searchString: string, IsFromHomeSearch: boolean) => {
  return async function GetTrackingStatus(dispatch) {
    const methodName = 'GetTrackingStatus';
    try {
      dispatch(TrackingActions.resetState());
      dispatch(TrackingActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Tracking_GetTrackingStatus, {
        SearchString: searchString,
        IsFromHomeSearch: IsFromHomeSearch,
      });
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: TrackingStatusResponse[] = response.parsedBody?.Results;

      dispatch(TrackingActions.SetTrackingStatusResponse(data));
      dispatch(TrackingActions.submitStatusSucceeded());
    } catch (e) {
      dispatch(TrackingActions.SetTrackingStatusResponse([]));
      dispatch(TrackingActions.submitStatusSucceeded());

      throw Error();
    }
  };
};

export const SaveTrackingDetails = (request: TrackingDetailsViewModel) => {
  return async function SaveTrackingDetails(dispatch) {
    const methodName = 'SaveTrackingDetails';
    try {
      // dispatch(TrackingActions.resetState());
      dispatch(TrackingActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Tracking_Save, request);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(TrackingActions.SetUploadBarcodeRefResponse(response.parsedBody?.Results));
      dispatch(TrackingActions.submitStatusSucceeded());
      if (response.parsedBody?.Results == 'S') {
        await dispatch(GetAllTrackingManagement());
      }
    } catch (e) {
      dispatch(TrackingActions.SetTrackingStatusResponse([]));
      dispatch(TrackingActions.submitStatusSucceeded());
      message.error('An error occurred while saving the data. Please try again.');
      throw Error();
    }
  };
};

export const GetAllTrackingManagement = () => {
  return async function GetAllTrackingManagement(dispatch) {
    const methodName = 'GetAllTrackingManagement';
    try {
      // dispatch(TrackingActions.resetState());
      dispatch(TrackingActions.submitStatus());
      const response = await GetData<APIResponse>(KFSEndpoints.Tracking_GetAllTrackingManagement);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      const data: TrackingManagementListResponse[] = response.parsedBody?.Results;

      dispatch(TrackingActions.SetTrackingManagementListResponse(data));
      dispatch(TrackingActions.submitStatusSucceeded());
    } catch (e) {
      dispatch(TrackingActions.SetTrackingStatusResponse([]));
      dispatch(TrackingActions.submitStatusSucceeded());

      throw Error();
    }
  };
};

export const DeleteTrackingStatus = (request: string) => {
  return async function DeleteTrackingStatus(dispatch) {
    const methodName = 'DeleteTrackingStatus';
    try {
      // dispatch(TrackingActions.resetState());
      dispatch(TrackingActions.submitStatus());
      const response = await PostData<APIResponse>(KFSEndpoints.Tracking_Delete, request);
      new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
      dispatch(TrackingActions.submitStatusSucceeded());
      await dispatch(GetAllTrackingManagement());
      message.success('Record successfully deleted');
    } catch (e) {
      dispatch(TrackingActions.SetTrackingStatusResponse([]));
      dispatch(TrackingActions.submitStatusSucceeded());

      throw Error();
    }
  };
};
