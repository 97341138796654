import { Button, Form } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import styles from 'features/Booking/Booking.module.less';
import React, { useRef } from 'react';

interface SignaturePadProps {
  signatureRef: React.RefObject<SignatureCanvas>;
  onClear: () => void;
  // width?: number;
  // height?: number;
}

const DeclarationSignature: React.FC<SignaturePadProps> = ({ signatureRef, onClear }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <Form.Item label="Signature" name="signature" className="mb-0">
        <div className={styles.sigCanvasWrapper} ref={containerRef}>
          <SignatureCanvas ref={signatureRef} penColor="black" canvasProps={{ className: styles.sigCanvas }} />
        </div>
      </Form.Item>
      <Button onClick={onClear} className={styles.clearSignatureBtn}>
        Clear signature
      </Button>
    </>
  );
};

export default DeclarationSignature;
