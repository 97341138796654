import { BarcodeActions } from '@features/Barcode';
import { BookingActions } from '@features/Booking';
import { ClearCache } from '@features/Login';
import { ReceiverActions } from '@features/Receiver';
import { SenderDetailsActions } from '@features/SenderDetails';
import { TrackingActions } from '@features/Tracking';
import { TransactionActions } from '@features/Transaction';
import { PATHS } from '@routing/routes';
import { Alert, Button, Col, Form, message, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ConfirmNow } from '../business/AccountBusiness';

export const AccountNotConfirm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onFinish = () => {
    message.success('Confirmation email sent. Please confirm your account and log in again.');

    dispatch(ConfirmNow());
    ClearCache();
    dispatch(BookingActions.resetState());
    dispatch(SenderDetailsActions.resetState());
    dispatch(ReceiverActions.resetState());
    dispatch(BarcodeActions.resetState());
    dispatch(TransactionActions.resetState());
    dispatch(TrackingActions.resetState());
    history.push(PATHS.Login.path);
  };

  return (
    <div className="App">
      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col>
            <br />
            {/* <label>Account not confirmed. Please confirm your account or proceed later.</label> */}
            <Alert
              message="Warning"
              description="Account not confirmed. Please confirm your account or proceed later."
              type="warning"
              showIcon
            />
            <br />
            <br />
            <Button className="prevSenderBtn" size="large" type="default" htmlType="submit">
              Confirm now
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" onClick={() => history.push('/home')}>
              Confirm later
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
