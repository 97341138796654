import { Col, Row, Steps } from 'antd';
import '@features/Webpage/homepage.css';
import {
  ReferReward,
  getRewarded,
  loyalty,
  loyalty2,
  phoneIconExport,
  referFamily,
  referred,
  sharelove,
} from 'common/images';
const { Step } = Steps;
export const TermsAndConditionRewards = () => {
  return (
    <>
      <h1 className="center">Terms and conditions of our perks</h1>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h3>Sign up bonus</h3>
          <Steps direction="vertical">
            <Step
              title={
                <div className="custom-title">
                  Create an online account with us and earn $10 credit that you can use in your first balikbayan box
                  shipment.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={phoneIconExport} alt="Step 1" className="rewardsIcon" />}
            />
          </Steps>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h3>Refer a friend</h3>
          <Steps direction="vertical">
            <Step
              title={
                <div className="custom-title custom-title-pd">
                  <b> Get your family and friends to give us a go</b>. You’ll both score $10 when they send their first
                  shipment through KFS.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={referFamily} alt="Step 1" className="rewardsIcon" />}
            />
            <Step
              title={
                <div className="custom-title custom-title-pd">
                  <b> Share the love</b>. Through your KFS online account, initiate an invitation using your friend’s
                  email address.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={sharelove} alt="Step 2" className="rewardsIcon" />}
            />
            <Step
              title={
                <div className="custom-title custom-title-pd">
                  <b>Get rewarded</b>. When your friend signs up for a KFS online account using the invitation link
                  you’ve sent, we will take note of that. Once your friend’s very first balikbayan box has been picked
                  up, you’ll get $10 referral bonus.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={getRewarded} alt="Step 2" />}
            />
            <Step
              title={
                <div className="custom-title custom-title-pd">
                  <b>Refer, reward, repeat</b>. You can accumulate the bonus through your referrals to cover the cost of
                  your next booking with us, in whole or partially.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={ReferReward} alt="Step 2" />}
            />
            <Step
              title={
                <div className="custom-title custom-title-pd">
                  <b>Have you been referred?</b> Be sure to sign up using the invitation link. You’ll get the $10 credit
                  in your first shipment.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={referred} alt="Step 2" />}
            />
          </Steps>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h3>Loyalty points</h3>
          <Steps direction="vertical">
            <Step
              title={
                <div className="custom-title custom-title-pd welltake">
                  We’ll take note the number of balikbayan boxes you have sent through KFS.
                  <br />
                  <br />
                  You may opt to claim 50% off on the cost of one box on your sixth booking or decide to wait, and get
                  the eleventh box free of charge.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={loyalty} alt="Step 1" />}
            />
          </Steps>
          <h3>But wait, there’s more!</h3>
          <Steps direction="vertical">
            <Step
              title={
                <div className="custom-title custom-title-pd">
                  The good thing is, bonus and loyalty points will never expire. But please remember, they are not
                  transferrable.
                </div>
              }
              className="custom-step custom-step1 "
              icon={<img src={loyalty2} alt="Step 1" />}
            />
          </Steps>
        </Col>
      </Row>
    </>
  );
};
