import { notification } from 'antd';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, GetData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { HandleErrorMessage } from '@features/Common';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { EmployeeActions } from '../store/EmployeeSlice';
import { EmployeeViewModel } from 'common/models';

export const EmployeeGetAllAgent = () => async (dispatch) => {
  const methodName = 'EmployeeGetAll';
  try {
    dispatch(EmployeeActions.submitStatus());
    const response = await GetData<APIResponse>(KFSEndpoints.Employee_GetAllAgent);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const data: EmployeeViewModel[] = response.parsedBody?.Results;
    dispatch(EmployeeActions.SetEmployee(data));
  } catch (e) {
    dispatch(EmployeeActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};
