/* eslint-disable no-inline-styles/no-inline-styles */
import { Layout, Divider } from 'antd';
import styles from './AppHeader.module.less';
import { kfsLogo } from 'common/images';
import { Link } from 'react-router-dom';
import { PATHS } from '@routing/routes';
import { CustomMenu } from '../CustomMenu';
import { areLoginCookiesEmpty } from '@features/Login/business/AccountLoginBusiness';
import { AccountSelector } from '@features/Account';
import { useSelector } from 'react-redux';
import { CustomMenuHomepage } from '../CustomMenu/CustomMenuHomepage';
import { UserOutlined } from '@ant-design/icons';
const { Header } = Layout;

export const AppHeader = () => {
  const accountState = useSelector(AccountSelector);

  const path =
    accountState.RoleName !== null && accountState.RoleName !== undefined ? PATHS.Home.path : PATHS.HomePage.path;
  return (
    <>
      <Header className={`${styles.Header} width100 non-printable webPageBigMenu`}>
        <div className={styles.First}>
          <Link to={path}>
            <img src={kfsLogo} alt="logo" className="mb-10 mt-10" />
          </Link>
          {/* <Divider className={styles.Divider} type="vertical" /> */}
        </div>
        {accountState.RoleName !== null && accountState.RoleName !== undefined && (
          <div className={styles.Second}>{<CustomMenu />}</div>
        )}

        {accountState.RoleName === null ||
          (accountState.RoleName === undefined && <div className={styles.Second}>{<CustomMenuHomepage />}</div>)}
      </Header>

      <Header className={`${styles.Header} width100 non-printable webPageSmallMenu`}>
        {accountState.RoleName !== null && accountState.RoleName !== undefined && (
          <>
            <div className="logo-and-menu-container">
              <Link to={path}>
                <img src={kfsLogo} alt="logo" className="logo" />
              </Link>
              <div className="menu-container">
                <CustomMenu />
              </div>
            </div>
          </>
        )}
        {accountState.RoleName === null ||
          (accountState.RoleName === undefined && (
            <div className="logo-and-menu-container">
              <Link to={path}>
                <img src={kfsLogo} alt="logo" className="logo" />
              </Link>
              <div className="menu-container">
                <CustomMenuHomepage />
              </div>
            </div>
          ))}
      </Header>
    </>
  );
};
