import { underconstruction } from 'common/images';
import SenderCommon from './SenderCommon';
import { PATHS } from '@routing/routes';
import { Constants } from 'common/constants';

export const UnderConstructionPage = () => {
  return (
    <div>
      <SenderCommon
        header="Sender"
        showSenderForm={true}
        onBack={'/'}
        onNext={PATHS.CommonAddress.path}
        buttonNames={{
          prevButton: Constants.BTN_BACK,
          nextButton: Constants.BTN_CONTINUE,
        }}
      />
    </div>

    // <div>
    //   <h1>Under Construction</h1>
    //   <p>We are currently building something awesome. Please check back later.</p>
    //   <img src={underconstruction} />
    // </div>
  );
};

export default UnderConstructionPage;
