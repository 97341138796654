import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SenderStatus } from 'common/constants';
import { SenderModel } from 'common/models/BookingTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'SenderDetails';

interface ISenderDetailsState {
  submitStatus: OperationStatus;
  Sender?: SenderModel;
  SenderStatus: typeof SenderStatus.New | typeof SenderStatus.Update | typeof SenderStatus.Booking;
  SelectedId?: string;
  FirstSender: boolean;
  ShowSenderForm: boolean;
}

const initialState: ISenderDetailsState = {
  submitStatus: OperationStatus.idle,
  SenderStatus: SenderStatus.New,
  Sender: undefined,
  SelectedId: undefined,
  FirstSender: false,
  ShowSenderForm: false,
};

export const SenderDetailsSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetSenderStatus: (
      state,
      action: PayloadAction<typeof SenderStatus.New | typeof SenderStatus.Update | typeof SenderStatus.Booking>
    ) => {
      state.SenderStatus = action.payload;
    },
    SetSender: (state, action: PayloadAction<SenderModel>) => {
      state.Sender = {
        ...state.Sender,
        ...action.payload,
      };
    },
    SetSelectedId: (state, action: PayloadAction<string>) => {
      state.SelectedId = action.payload;
    },
    SetShowSenderForm: (state, action: PayloadAction<boolean>) => {
      state.ShowSenderForm = action.payload;
    },
    SetFirstSender: (state, action: PayloadAction<boolean>) => {
      state.FirstSender = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state, action: PayloadAction<SenderModel | undefined>) => {
      state.Sender = action.payload;
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: SenderDetailsActions } = SenderDetailsSlice;

export default SenderDetailsSlice.reducer;
