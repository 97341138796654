import { Breadcrumb, Button, Col, Form, Input, Row, DatePicker, Space, Tag, Table } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Constants } from 'common/constants';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
  {
    key: '4',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
  {
    key: '5',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];
export const SenderDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const onFinish = (values: any) => {
    // alert(JSON.stringify(values));
  };

  return (
    <div className="App">
      <Row>
        <h1>Sender Details</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Form.Item
              label="Reference No."
              name="ReferenceNo"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="middle" maxLength={10} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Form.Item
              className="card-custom"
              label="Date Range"
              name="date"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <RangePicker className="card-custom" size="middle" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}></Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() => history.push('/booking/senderaddress')}
            >
              Close
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              className="custom-table"
              columns={columns}
              dataSource={data}
              pagination={{
                pageSize: 2, // Number of items per page
                //total: data.length, // Total number of items
                // showSizeChanger: true, // Show the page size changer
                // showQuickJumper: true, // Show quick jump to page
                // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
