import { message, notification } from 'antd';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { Validator } from 'common/validators/validators';
import { ContainerViewModel, DeleteBarcodeRequest, UploadBarcodeResponse } from 'common/models/ContainerTypes';
import { HandleErrorMessage } from '@features/Common';
import { InterStateActions } from '../store/InterStateSlice';
import { GetNotificationProps } from 'common/utils/utility';
import {
  InterStateBarcodeViewModel,
  InterStateDetailsViewModel,
  InterStateViewModel,
  UploadBarcodeInterStateRequest,
} from 'common/models/InterStateTypes';

export const Save = (request: InterStateViewModel) => async (dispatch) => {
  const methodName = 'Save';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterState_Save, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(InterStateActions.submitStatusSucceeded());
    if (request.IsDeleted) {
      message.success('Inter state: ' + request.TrfId + ' deleted successfully');
    } else if (request.Id) {
      message.success('Inter state: ' + request.TrfId + ' updated successfully');
    } else {
      message.success('Inter state: ' + request.TrfId + ' saved successfully');
    }
    dispatch(InterStateActions.SetSelectedInterStateId(response.parsedBody?.Results));
    await dispatch(GetById(response.parsedBody?.Results));
    // await dispatch(GetContainerDetailsByContainerId(response.parsedBody?.Results));
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    message.error('The inter state id is already in use. Please enter a different inter state id.');
  }
};

export const SaveInterStateDetails = (request: InterStateDetailsViewModel) => async (dispatch) => {
  const methodName = 'Save';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterStateDetails_Save, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(InterStateActions.submitStatusSucceeded());
    if (request.Id) {
      message.success('Inter state status updated successfully');
    } else {
      message.success('Inter state status saved successfully');
    }
    dispatch(InterStateActions.SetSelectedInterStateId(response.parsedBody?.Results));
    GetAllInterStateDetailsId(response.parsedBody?.Results);
    await dispatch(GetById(response.parsedBody?.Results));
    // await dispatch(GetContainerDetailsByContainerId(response.parsedBody?.Results));
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    message.error('The inter state id is already in use. Please enter a different inter state id.');
  }
};

export const GetAll = () => async (dispatch) => {
  const methodName = 'GetAll';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterState_GetAll, {});
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(InterStateActions.submitStatusSucceeded());
    const data: InterStateViewModel[] = response.parsedBody?.Results;
    dispatch(InterStateActions.SetInterStateList(data));
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetById = (id: string) => async (dispatch) => {
  const methodName = 'GetById';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterState_GetById, id);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const data: InterStateViewModel = response.parsedBody?.Results;
    dispatch(InterStateActions.SetInterState(data));
    dispatch(InterStateActions.submitStatusSucceeded());
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetAllInterStateDetailsId = (id: string) => async (dispatch) => {
  const methodName = 'GetAllInterStateDetailsId';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterStateDetails_GetInterStateDetails, id);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const data: InterStateDetailsViewModel[] = response.parsedBody?.Results;
    dispatch(InterStateActions.SetInterStateDetailsList(data));
    dispatch(InterStateActions.submitStatusSucceeded());
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const DeleteInterState = (id: string) => async (dispatch) => {
  const methodName = 'DeleteInterState';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterStateDetails_Delete, id);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    message.success('Deleted successfully');
    dispatch(InterStateActions.submitStatusSucceeded());
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const LoadBarcodeInterState = (request: UploadBarcodeInterStateRequest) => async (dispatch) => {
  const methodName = 'LoadToContainer';

  try {
    dispatch(InterStateActions.submitStatus());
    dispatch(InterStateActions.SetUploadBarcodeResponse(undefined));
    const response = await PostData<APIResponse>(KFSEndpoints.InterState_UploadBarcode, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(InterStateActions.submitStatusSucceeded());
    const data: UploadBarcodeResponse = response.parsedBody?.Results;
    if (data.IsSuccess) {
      message.success('Barcode successfully uploaded');
    }
    dispatch(InterStateActions.SetUploadBarcodeResponse(data));
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
  }
};

export const GetInterStateBarcodeDetails = (request: string) => async (dispatch) => {
  const methodName = 'GetInterStateBarcodeDetails';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterState_GetInterStateBarcodeDetails, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(InterStateActions.submitStatusSucceeded());
    const data: InterStateBarcodeViewModel[] = response.parsedBody?.Results;
    dispatch(InterStateActions.SetInterStateBarcodeList(data));
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const DeleteInterStateBarcode = (request: DeleteBarcodeRequest) => async (dispatch) => {
  const methodName = 'DeleteInterStateBarcode';

  try {
    dispatch(InterStateActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.InterState_DeleteBarcode, request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(InterStateActions.submitStatusSucceeded());
    message.success('Barcode successfully deleted');
  } catch (e) {
    dispatch(InterStateActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};
