import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb, Button, Col, Form, Input, Row, DatePicker, Space, Tag, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { TransactionsHistoryViewModel } from 'common/models/BookingTypes';
import { BookingActions } from '../store/BookingSlice';
import { BookingSelector } from '../store/BookingSelector';
import { Constants, PageSize, TransactionStatus, TransactionType } from 'common/constants';
import { GetCustomerTransactionsHistory } from '../business/BookingBusiness';
import { OperationStatus } from 'store/rootTypes';
import { TransactionActions } from '@features/Transaction';
import { PATHS } from '@routing/routes';

export const Listing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const bookingState = useSelector(BookingSelector);

  const onFinish = (values: any) => {
    dispatch(BookingActions.SetReceiver({ ...values }));
    history.push('/booking/boxcontent');
  };

  const onTableClick = (recordId: string, type: string) => {
    dispatch(TransactionActions.SetSelectedTransId(recordId));
    if (type == TransactionType.Booking) {
      history.push('/transaction/summary');
    } else {
      history.push(PATHS.SummaryOrderBox.path);
    }
  };

  const columns: ColumnsType<TransactionsHistoryViewModel> = [
    {
      title: '',
      key: 'details',
      render: (_, record) => (
        <Space size="middle" onClick={() => onTableClick(record.Id, record.Type)}>
          <div>
            {/* <b>{record.Type}</b>
            <br /> */}
            <b>{record.RefNo}</b> {record.SendOn}
            <br />
            <span>{record.Status}</span>
            {record.Type == TransactionType.OrderBox && (
              <>
                <br />
                <b>Empty box order</b>
              </>
            )}
          </div>
        </Space>
      ),
    },
  ];

  const data: TransactionsHistoryViewModel[] = bookingState.TransactionHistory || [];
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetCustomerTransactionsHistory());
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Transaction history</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={16} lg={12} xl={6}>
            <Table
              loading={bookingState.submitStatus == OperationStatus.pending}
              className="custom-table"
              columns={columns}
              dataSource={data}
              pagination={data.length > PageSize.TransactionHistory ? { pageSize: PageSize.TransactionHistory } : false}
            />
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
            <Button
              className="prevSenderBtn full-width"
              size="large"
              type="default"
              onClick={() => history.push('/home')}
            >
              {Constants.BTN_CLOSE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Listing;
