import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Form, Row, Space, Table, Input, Button, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TransactionsHistoryViewModel } from 'common/models/BookingTypes';
import { Constants, MessageDisplay, PageSize, Roles } from 'common/constants';
import { OperationStatus } from 'store/rootTypes';
import { BookingSelector, GetCustomerTransactionsHistory } from '@features/Booking';
import { TrackingSelector } from '../store/TrackingSelector';
import { TrackingActions } from '../store/TrackingSlice';
import { AccountSelector } from '@features/Account';
import { GetTrackingStatus } from '../business/TrackingBusiness';

export const Tracking = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingState = useSelector(BookingSelector);
  const trackingState = useSelector(TrackingSelector);
  const accountState = useSelector(AccountSelector);
  const onFinish = async (values: any) => {
    try {
      if (values.searchString) {
        await dispatch(GetTrackingStatus(values.searchString, false));
        dispatch(TrackingActions.SetSearchString(values.searchString));
        history.push('/tracking/status');
      }
    } catch (error) {
      message.error(MessageDisplay.EMPTY_NO_RECORDS_FOUND, 2);
    }
  };

  const onTableClick = (data: string) => {
    dispatch(TrackingActions.SetSearchString(data));
    history.push('/tracking/status');
  };

  useEffect(() => {
    dispatch(TrackingActions.SetTrackingStatusResponse([]));
  }, []);

  const columns: ColumnsType<TransactionsHistoryViewModel> = [
    {
      title: '',
      key: 'details',
      render: (_, record) => (
        <Space size="middle" onClick={() => onTableClick(record.RefNo)}>
          <div>
            <b>{record.RefNo}</b> {record.SendOn}
            <br />
            <span>{record.Status}</span>
          </div>
        </Space>
      ),
    },
  ];

  const data: TransactionsHistoryViewModel[] = bookingState.TransactionHistory || [];
  useEffect(() => {
    const fetchData = async () => {
      if (accountState.RoleName == Roles.Customer) {
        await dispatch(GetCustomerTransactionsHistory());
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Tracking</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={16} lg={12} xl={6}>
            {accountState.RoleName != Roles.Customer && (
              <>
                <Form.Item
                  label="Reference / Barcode no."
                  name="searchString"
                  initialValue={trackingState.SearchString}
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={150} />
                </Form.Item>

                <Button
                  className="prevSenderBtn"
                  loading={trackingState.submitStatus === OperationStatus.pending}
                  size="large"
                  type="default"
                  onClick={() => history.push('/home')}
                >
                  {Constants.BTN_CLOSE}
                </Button>
                <Button
                  loading={trackingState.submitStatus === OperationStatus.pending}
                  className="nextSenderBtn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {Constants.BTN_VIEW}
                </Button>
              </>
            )}

            {accountState.RoleName == Roles.Customer && (
              <>
                <Table
                  loading={trackingState.submitStatus == OperationStatus.pending}
                  className="custom-table"
                  columns={columns}
                  dataSource={data}
                  pagination={
                    data.length > PageSize.TransactionHistory ? { pageSize: PageSize.TransactionHistory } : false
                  }
                />
                <br />
                <Button
                  className="prevSenderBtn full-width "
                  size="large"
                  type="default"
                  onClick={() => history.push('/home')}
                >
                  {Constants.BTN_CLOSE}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Tracking;
