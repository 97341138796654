import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BoxContentModel, BoxDetailsModel, ReceiverModel, SenderModel } from 'common/models/BookingTypes';
import { TrackingManagementListResponse, TrackingStatusResponse } from 'common/models/TrackingTypes';
import { OperationStatus } from 'store/rootTypes';

const name = 'Tracking';

interface ITrackingState {
  submitStatus: OperationStatus;
  SearchString: string;
  TrackingStatusResponse: TrackingStatusResponse[];
  UploadBarcodeRefResponse: string;
  TrackingManagementListResponse: TrackingManagementListResponse[];
}

const initialState: ITrackingState = {
  submitStatus: OperationStatus.idle,
  SearchString: '',
  TrackingStatusResponse: [],
  UploadBarcodeRefResponse: 'P', //PENDINGG
  TrackingManagementListResponse: [],
};

export const TrackingSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    SetSearchString: (state, action: PayloadAction<string>) => {
      state.SearchString = action.payload;
    },
    SetUploadBarcodeRefResponse: (state, action: PayloadAction<string>) => {
      state.UploadBarcodeRefResponse = action.payload;
    },
    SetTrackingStatusResponse: (state, action: PayloadAction<TrackingStatusResponse[]>) => {
      state.TrackingStatusResponse = action.payload;
    },
    SetTrackingManagementListResponse: (state, action: PayloadAction<TrackingManagementListResponse[]>) => {
      state.TrackingManagementListResponse = action.payload;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: TrackingActions } = TrackingSlice;

export default TrackingSlice.reducer;
