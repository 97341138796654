import { configureStore, Middleware } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { persistedReducer } from './rootReducer';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { asyncFunctionMiddleware } from './asyncFunctionMiddleware';

export const history = createBrowserHistory({ basename: '/' });

const middlewares: Middleware[] = [routerMiddleware(history), asyncFunctionMiddleware];

// The store has been created with these options:
// - the store is fully persisted
// - redux-saga is added as middleware
// - the Redux DevTools Extension is disabled for production
export const store = configureStore({
  reducer: persistedReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([...middlewares]),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
