import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { store } from 'store';

/**
 * A generic type for representing any API status
 *  idle: API is not called yet
 *  pending: API has been called and response is pending
 *  success: API response is returned successfully
 *  error: API encounters error to return expected response
 * Use this to indicate the status of API call on the page, e.g. showing loading spinner/ skeleton for "pending"
 */

// export type IApiStatus = { [key in ApiStatus]: string };

export enum ApiStatus {
  idle = 'idle',
  pending = 'pending',
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum OperationStatus {
  idle = 'I',
  pending = 'P',
  succeeded = 'S',
  failed = 'F',
}

/**
  * A generic type to make every object property nullable in Typescript
  * reference: https://typeofnan.dev/making-every-object-property-nullable-in-typescript/
  *
  * e.g.
  * type Person = {
   name: string;
   email: string;
   age: number;
   admin: boolean;
   dog: {
     name: string;
     age: number;
   };
 };

 * Now if we implement this on our new Person type, we can see that our object can be nullable all the way down.

 const nullablePerson: DeepNullable<Person> = {
   name: 'Daffodil',
   email: null,
   age: null,
   admin: true,
   dog: {
     name: 'Fido',
     age: null,
   },
 };

 This allows us not declaring NULL type for all the fields for the object
  */
export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

/**
 * A generic item representing form data item.
 *
 * Used for easy rendering on the UI
 * "id" : used as key of component
 * "text" : UI-friendly name for this data item.
 */
export interface IterableElement {
  id: string | number;
  text: string;
  disabled?: boolean;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface BackendError {
  type: string;
  title: string;
  status: number;
  traceId: string;
}
