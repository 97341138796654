import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Form, Row, Table, Button, Select, Modal, DatePicker, Alert } from 'antd';
import { Constants } from 'common/constants';
import { OperationStatus } from 'store/rootTypes';
import { TrackingSelector } from '../store/TrackingSelector';
import { TrackingActions } from '../store/TrackingSlice';
import { AccountSelector } from '@features/Account';
import { DeleteTrackingStatus, GetAllTrackingManagement, SaveTrackingDetails } from '../business/TrackingBusiness';
import { PATHS } from '@routing/routes';
import { commonSelector } from '@features/Common/store/commonSelector';
import { GetCodeSets } from '@features/Common';
import { CodeSets } from 'common/models/CodeSetsTypes';
import TextArea from 'antd/es/input/TextArea';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { TrackingDetailsViewModel, TrackingManagementListResponse } from 'common/models';

export const TrackingManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const trackingState = useSelector(TrackingSelector);
  const accountState = useSelector(AccountSelector);
  const commonState = useSelector(commonSelector);
  const [formTransaction] = Form.useForm();
  const validCodes: Set<string> = new Set(['NW', 'PI', 'ITRA', 'ITD', 'ITIS', 'ITADP', 'ITWHIPH', 'OFD', 'DE']);
  const codeSet: CodeSets[] =
    commonState.CodeSets?.filter((x) => validCodes.has(x.Code) && x.Category == 'TrackingDetailsDropdown').sort(
      (x) => x.Orders
    ) || [];
  const onFinish = async (values: TrackingDetailsViewModel) => {
    await dispatch(SaveTrackingDetails(values));
  };
  const onFinishFailed = async () => {
    await dispatch(TrackingActions.SetUploadBarcodeRefResponse('P'));
  };

  useEffect(() => {
    if (trackingState.UploadBarcodeRefResponse == 'S') {
      formTransaction.resetFields();
    }
  }, [trackingState.UploadBarcodeRefResponse]);

  useEffect(() => {
    dispatch(TrackingActions.SetTrackingStatusResponse([]));
    if (!commonState.CodeSets) {
      dispatch(GetCodeSets());
    }
    // alert(JSON.stringify(commonState.CodeSets));
  }, []);

  const data: TrackingManagementListResponse[] = trackingState.TrackingManagementListResponse || [];
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetAllTrackingManagement());
    };

    fetchData();
  }, [dispatch]);

  const columns = [
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      title: 'BarcodeNo',
      dataIndex: 'BarcodeNo',
      key: 'BarcodeNo',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'Comments',
      dataIndex: 'Comments',
      key: 'Comments',
    },
    {
      title: 'Action',
      dataIndex: 'Load',
      key: 'Load',
      render: (text, record) => (
        <span>
          <a onClick={() => showConfirm(record)}>Delete</a>
        </span>
      ),
    },
  ];

  const { confirm } = Modal;

  const showConfirm = (data: TrackingManagementListResponse) => {
    confirm({
      title: 'Do you want to delete this recored?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        await dispatch(DeleteTrackingStatus(data.Id));
      },
      async onOk() {
        //conso
      },
    });
  };
  return (
    <div className="App">
      <Row>
        <h1>{PATHS.TrackingManagement.displayTitle}</h1>
      </Row>

      <Form
        name="senderForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={formTransaction}
        layout="vertical"
      >
        <br />
        <Row gutter={[10, 0]}>
          <Col xs={24} sm={20} md={12} lg={8} xl={6}>
            <Form.Item
              label="Status"
              name="Status"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              // initialValue={transactionState.TransactionStatusDetailsViewModel?.Type}
            >
              <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                {codeSet.map((destination) => (
                  <Select.Option key={destination.Code} value={destination.Code}>
                    {destination.Code === 'ITRA'
                      ? 'Received - with KFS agent'
                      : destination.Code === 'ITD'
                      ? 'In-transit'
                      : destination.Code === 'ITADP'
                      ? 'Arrived - Manila port'
                      : destination.Code === 'ITIS'
                      ? 'Inter state'
                      : // : destination.Code === 'DE'
                        // ? 'Delivered - received by [name]'
                        destination.Display}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={20} md={12} lg={8} xl={6}>
            <Form.Item
              label="Date" //"Out for delivery"
              name="Date"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              // initialValue={convertToDayjs(transactionState.TransactionDelivery?.Barcode[0].OutForDelivery) ?? ''}
            >
              <DatePicker
                size="large"
                className="full-width"
                // onChange={handleDateChangeOutForDelivery}
                format="YYYY-MM-DD" // Specify the format of the date picker
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item
              label={
                <span>
                  Insert barcode number(s) here. You can enter one or multiple.
                  <br />
                  Please follow the correct format (e.g., NSW995742S, NSW467106S). New lines are allowed.
                  <br />
                </span>
              }
              name="Details"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <TextArea size="large" maxLength={500} rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Form.Item label="Comments" name="Comments" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <TextArea size="large" maxLength={500} rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            {trackingState.UploadBarcodeRefResponse == 'S' && (
              <>
                <Alert message="Barcode(s) successfully uploaded." type="success" showIcon />
                <br />
              </>
            )}

            {trackingState.UploadBarcodeRefResponse !== 'S' && trackingState.UploadBarcodeRefResponse !== 'P' && (
              <>
                <Alert message={trackingState.UploadBarcodeRefResponse} type="error" showIcon />
                <br />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Button
              className="prevSenderBtn"
              loading={trackingState.submitStatus === OperationStatus.pending}
              size="large"
              type="default"
              onClick={() => history.push('/home')}
            >
              {Constants.BTN_CLOSE}
            </Button>
            <Button
              loading={trackingState.submitStatus === OperationStatus.pending}
              className="nextSenderBtn"
              size="large"
              type="primary"
              htmlType="submit"
            >
              {Constants.BTN_SAVE}
            </Button>
          </Col>
        </Row>
        <br />
        <Table dataSource={data} columns={columns} />
      </Form>
    </div>
  );
};

export default TrackingManagement;
