import { Button, Col, Row, DatePicker, Modal, Breadcrumb, Form } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingSelector } from '../store/BookingSelector';
import { useEffect, useRef, useState } from 'react';
import { Constants, Roles } from 'common/constants';
import { TermsAndCondition } from '@features/Webpage/components/TermsAndCondition';
import DeclarationSignature from './DeclarationSignature';
import { BookingActions } from '../store/BookingSlice';
import { AccountSelector } from '@features/Account';

export const Declaration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const bookingState = useSelector(BookingSelector);
  const [modalVisible, setModalVisible] = useState(false);

  const handleViewTermsClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const [form] = Form.useForm();
  const accountState = useSelector(AccountSelector);
  const handleAgree = () => {
    // Perform actions when the user agrees (e.g., update state, submit form)
    // You can add your custom logic here
    setModalVisible(false); // Close the modal
  };
  const onFinish = (values: any) => {
    const isSignatureEmpty = signatureRef.current.isEmpty();
    if (accountState.RoleName != Roles.Customer && isSignatureEmpty) {
      dispatch(BookingActions.SetSignature(''));
      history.push('/booking/summary');
    }
    if (isSignatureEmpty) {
      form.setFields([
        {
          name: 'signature',
          errors: [Constants.Signature_Required],
        },
      ]);
      return;
    }
    form.setFields([
      {
        name: 'signature',
        errors: [''],
      },
    ]);

    if (signatureRef.current) {
      const base64Signature = signatureRef.current.toDataURL();

      dispatch(BookingActions.SetSignature(base64Signature));

      history.push('/booking/summary');
    }
  };
  const signatureRef = useRef<any>(null);
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  useEffect(() => {
    if (bookingState.Signature !== undefined) {
      signatureRef.current.fromDataURL(bookingState.Signature);
    }
  }, []);
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <Col>
          <h1>Sender‘s declaration</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={20} md={16} lg={12} xl={8}>
          <Breadcrumb
            items={[
              {
                title: 'Sender',
              },
              {
                title: 'Sender address',
              },
              {
                title: 'Receiver',
              },
              {
                title: 'Box content',
              },
              {
                title: 'Pick up and box details',
              },
              {
                title: bookingState.ReferralResponse?.IsFirstTransaction ? 'Perks for you' : null,
              },
              {
                title: 'Declaration',
              },
            ]}
          />
          <br />
          <span>This is to certify that:</span>
          <ul>
            <li>I am the sender of the items contained in this parcel being sent to the Philippines;</li>
            <li>The listed items are accurate;</li>
            <li>The items are for personal use only;</li>
            <li>
              There are no undeclared, restricted, illegal, or banned items including firearms, ammunition, illegal
              drugs, combustible goods in this shipment;
            </li>
            <li>I agree to the Terms and Conditions.</li>
          </ul>
          <span>
            {' '}
            <a onClick={handleViewTermsClick}>View full terms and conditions</a>
          </span>
        </Col>
      </Row>
      <Row justify="center">
        <Modal
          title=""
          visible={modalVisible}
          onCancel={handleCloseModal}
          footer={[
            <Button key="agree" type="primary" onClick={handleAgree}>
              Agree
            </Button>,
          ]}
        >
          <div className="scrollable-modal-content-TOC">
            <TermsAndCondition />
          </div>
        </Modal>
      </Row>
      <br />
      <Form name="senderForm" layout="vertical" form={form} onFinish={onFinish}>
        <Row justify="center" className="azzzaaaaaaaaaaa">
          <Col xs={24} sm={24} md={16} lg={12} xl={8}>
            <DeclarationSignature signatureRef={signatureRef} onClear={handleClearSignature} />
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={12} xl={8}>
            <Button
              className="prevSenderBtn"
              type="default"
              onClick={() =>
                history.push(
                  bookingState.ReferralResponse?.IsFirstTransaction ? '/booking/perks' : '/booking/boxdetails'
                )
              }
            >
              {Constants.BTN_BACK}
            </Button>
            {/* <Button className="nextSenderBtn" type="primary" onClick={() => history.push('/booking/summary')}>
            Accept and continue
          </Button> */}

            <Button className="nextSenderBtn" type="primary" htmlType="submit">
              Accept and continue
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
