import {
  BookingAPIRequestModel,
  BoxContentModel,
  BoxDetailsModel,
  ReferralResponse,
  TransactionsHistoryViewModel,
  TransactionsSaveResponse,
  TransactionsUpdateSenderRequest,
} from 'common/models/BookingTypes';
import { BookingActions } from '../store/BookingSlice';
import { notification } from 'antd';
import { GetNotificationProps } from 'common/utils/utility';
import { APIResponseCode, MessageDisplay } from 'common/constants';
import { APIResponse, GetData, PostData } from 'common/utils/jsonFetch';
import { Validator } from 'common/validators/validators';
import { HandleErrorMessage } from '@features/Common';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';

export const SubmitBooking = (request: BookingAPIRequestModel) => async (dispatch) => {
  const methodName = 'SubmitBooking';

  try {
    dispatch(BookingActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.Transaction_Save, request);

    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    const data: TransactionsSaveResponse = response.parsedBody?.Results;
    dispatch(BookingActions.submitStatusSucceeded(data));

    return data; // Return the data if needed
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const GetCustomerTransactionsHistory = () => async (dispatch) => {
  const methodName = 'GetCustomerTransactionsHistory';

  try {
    dispatch(BookingActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.Transaction_GetCustomerTransactionsHistory, null);

    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    const data: TransactionsHistoryViewModel[] = response.parsedBody?.Results;
    dispatch(BookingActions.SetTransactionHistory(data));

    return data; // Return the data if needed
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateBoxDetails = (request: BoxDetailsModel) => async (dispatch) => {
  const methodName = 'UpdateBoxDetails';

  try {
    dispatch(BookingActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.BoxDetails_Update, request);
    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(BookingActions.submitStatusSucceededNoPayload());
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateBoxContent = (request: BoxContentModel) => async (dispatch) => {
  const methodName = 'UpdateBoxContent';

  try {
    dispatch(BookingActions.submitStatus());
    const response = await PostData<APIResponse>(KFSEndpoints.BoxContent_Update, request);
    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(BookingActions.submitStatusSucceededNoPayload());
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const IsFirstTransaction = () => async (dispatch) => {
  const methodName = 'IsFirstTransaction';
  try {
    const response = await GetData<APIResponse>(KFSEndpoints.Transaction_IsFirstTransaction);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    const data: ReferralResponse = response.parsedBody?.Results;
    dispatch(BookingActions.SetReferralResponse(data));
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const UpdateTransactionSender = (request: TransactionsUpdateSenderRequest) => async (dispatch) => {
  const methodName = 'UpdateTransactionSender';

  try {
    dispatch(BookingActions.submitStatus());
    const response = await PostData<APIResponse>('/Transaction/UpdateSender', request);
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);
    dispatch(BookingActions.submitStatusSucceededNoPayload());
  } catch (e) {
    dispatch(BookingActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    notification.error(GetNotificationProps(MessageDisplay.GENERIC_TECHNICAL_ERROR));
    throw e; // Rethrow the error to propagate it further if needed
  }
};
