import { AccountActions } from '@features/Account';
import { HandleErrorMessage } from '@features/Common';
import { notification } from 'antd';
import { APIResponseCode, Constants, MessageDisplay } from 'common/constants';
import { KFSEndpoints } from 'common/constants/KFSEndpoints';
import { LoginRequestModel } from 'common/models/UserTypes';
import { APIResponse, PostData } from 'common/utils/jsonFetch';
import { GetNotificationProps, getCookie, setCookie } from 'common/utils/utility';
import { Validator } from 'common/validators/validators';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const LoginUser = async (request: LoginRequestModel) => async (dispatch) => {
  return PostData<APIResponse>(KFSEndpoints.User_Login, request)
    .then((response) => {
      if (response.parsedBody?.Status != APIResponseCode.SUCCESS) {
        const err = response.parsedBody?.Message ?? Constants.ERROR_INVALID_LOGIN;
        throw new Error(err);
      }

      const { Token, RefreshToken, RoleName, Username } = response.parsedBody?.Results || {};

      if (!Token || !RefreshToken || !RoleName) {
        throw new Error(Constants.ERROR_INVALID_LOGIN);
      }
      // alert(JSON.stringify(response.parsedBody?.Results));
      dispatch(AccountActions.SetUserName(Username));
      dispatch(AccountActions.SetRoleName(RoleName));
      setCookie(Constants.Token, Token, { path: '/' });
      setCookie(Constants.RefreshToken, RefreshToken, { path: '/' });
    })
    .catch((error) => {
      // Handle other errors if needed
      throw new Error(error);
    });
};

export const LoginUserAccount = (request: LoginRequestModel) => async (dispatch) => {
  const methodName = 'LoginUserAccount';

  try {
    dispatch(AccountActions.submitStatus());

    const response = await PostData<APIResponse>(KFSEndpoints.User_Login, request);

    // Handle API response
    new Validator(response.parsedBody?.Status).IsEqualsString(APIResponseCode.SUCCESS);

    const { Token, RefreshToken, RoleName, IsConfirm, Username, WorkLocationCountry } =
      response.parsedBody?.Results || {};

    if (!Token || !RefreshToken || !RoleName) {
      throw new Error(Constants.ERROR_INVALID_LOGIN);
    }
    setCookie(Constants.Token, Token, { path: '/' });
    setCookie(Constants.RefreshToken, RefreshToken, { path: '/' });
    dispatch(AccountActions.SetWorkLocationCountry(WorkLocationCountry));
    dispatch(AccountActions.SetUserName(Username));
    dispatch(AccountActions.SetIsConfirm(IsConfirm));
    dispatch(AccountActions.SetRoleName(RoleName));
    dispatch(AccountActions.submitStatusSucceeded());

    // Return the data if needed
    return IsConfirm;
  } catch (e) {
    dispatch(AccountActions.submitStatusRejected(HandleErrorMessage(methodName, e)));
    throw e; // Rethrow the error to propagate it further if needed
  }
};

export const areLoginCookiesEmpty = () => {
  const token = getCookie(Constants.Token);
  const refreshToken = getCookie(Constants.RefreshToken);
  return !token || !refreshToken;
};
