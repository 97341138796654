import React, { useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Menu, message, Row, Select, Space, Tag } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Constants } from 'common/constants';
import { ResetPasswordBusiness } from '../business/AccountBusiness';
import { PATHS } from '@routing/routes';

export const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const onFinish = async (values: any) => {
    setEmail(values.Email);
    await dispatch(ResetPasswordBusiness(values.Email));
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {/* <h4>{email ? 'Enter your email to reset password' : 'Reset password'}</h4> */}
        <h4>Reset password</h4>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        {!email && (
          <>
            <Row justify="center" align="middle">
              <Col xs={24} sm={20} md={12} lg={8} xl={8}>
                <br />
                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    { required: true, message: Constants.REQUIRED_FIELD },
                    {
                      pattern: Constants.EMAIL_REGEX_INPUT,
                      message: 'Please enter a valid email address.',
                    },
                  ]}
                >
                  <Input maxLength={150} size="large" />
                </Form.Item>

                <div>
                  <Button className="nextSenderBtn full-width" type="primary" htmlType="submit" size="large">
                    Reset password
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <br />
        {email && (
          <>
            <Row justify="center" align="middle">
              <Col xs={24} sm={20} md={12} lg={8} xl={8}>
                <label>
                  {' '}
                  If an account exists for {email}, you will get an email with instructions on resetting your password.
                  If it doesn’t arrive, be sure to check your spam folder.
                </label>
              </Col>
            </Row>
          </>
        )}
        <Row justify="center" align="middle">
          <Col>
            <Link to={PATHS.Login.path} className="normal-font">
              Back to Log in
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
