import { Button, Col, Divider, Form, QRCode, Row, Select, Space, Table } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Constants } from 'common/constants';
import { useEffect, useState } from 'react';
import '@features/Incentive/incentive.css';
import { QRlogo } from 'common/images';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { StoresSelector } from '../store/StoresSelector';
import { GetAllIncentives, GetAllStore } from '../business/StoreBusiness';
import { webBaseUrl } from 'common/utils/jsonFetch';
import { StoreIncentivesViewModel } from 'common/models/StoreTypes';
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { OperationStatus } from 'store/rootTypes';
import { PATHS } from '@routing/routes';
export const StoresIncentive = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const storeState = useSelector(StoresSelector);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedName, setSelectedName] = useState('');
  let data: StoreIncentivesViewModel[] = storeState.StoreIncentivesViewModel || [];
  const onFinish = async () => {
    setTableKey((tableKey) => tableKey + 1);
    await dispatch(GetAllIncentives(selectedValue));
    data = storeState.StoreIncentivesViewModel;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (storeState.Stores.length < 1) {
        await dispatch(GetAllStore());
      }
    };

    fetchData();
  }, []);

  const MIN_SIZE = 48;
  const MAX_SIZE = 500;
  const [size, setSize] = useState<number>(160);

  const increase = () => {
    setSize((prevSize) => {
      const newSize = prevSize + 10;
      if (newSize >= MAX_SIZE) {
        return MAX_SIZE;
      }
      return newSize;
    });
  };

  const decline = () => {
    setSize((prevSize) => {
      const newSize = prevSize - 10;
      if (newSize <= MIN_SIZE) {
        return MIN_SIZE;
      }
      return newSize;
    });
  };

  const handleChange = (value) => {
    setSelectedValue(value);
    const selectedStore = storeState.Stores.find((s) => s.Id === value);
    setSelectedName(`${selectedStore?.Code} ${selectedStore?.Name}`);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const statusOptions = Array.from(new Set(data.map((item) => item.Status))).map((Status) => ({
    text: Status,
    value: Status,
  }));
  const storeOptions = Array.from(new Set(data.map((item) => item.StoreName))).map((StoreName) => ({
    text: StoreName,
    value: StoreName,
  }));
  const columns = [
    {
      title: 'Store name',
      dataIndex: 'StoreName',
      key: 'StoreName',
      filters: storeOptions,
      onFilter: (value: string | number | boolean, record: StoreIncentivesViewModel) => {
        if (typeof value === 'string') {
          return record.StoreName.includes(value);
        }
        return false;
      },
    },
    {
      title: 'Reference no',
      dataIndex: 'Refno',
      key: 'Refno',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      filters: statusOptions,
      onFilter: (value: string | number | boolean, record: StoreIncentivesViewModel) => {
        if (typeof value === 'string') {
          return record.Status?.includes(value);
        }
        return false;
      },
    },
    {
      title: 'Sender',
      dataIndex: 'Sender',
      key: 'Sender',
    },
    {
      title: 'Suburb',
      dataIndex: 'Address',
      key: 'Address',
    },
    {
      title: 'Booking date',
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
    },
  ];
  const [tableKey, setTableKey] = useState(0);
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Store management</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Form.Item label="Store" name="StoreId">
              <Select size="large" showSearch placeholder="Select" optionFilterProp="children" onChange={handleChange}>
                <Select.Option key="" value="">
                  {'Select All'}
                </Select.Option>
                {storeState.Stores?.map((s) => (
                  <Select.Option key={s.Id} value={s.Id}>
                    {s.Code} {s.Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push(PATHS.Home.path)}>
              Back
            </Button>
            <Button
              className="nextSenderBtn"
              size="large"
              type="primary"
              htmlType="submit"
              loading={storeState.submitStatus == OperationStatus.pending}
            >
              View record
            </Button>
          </Col>
        </Row>
        <br />
        <Table key={tableKey} dataSource={data} columns={columns} locale={TableLocaleCustom} />

        <br />
        {selectedValue && (
          <>
            <Row justify="center" align="middle">
              <Button.Group>
                <Button onClick={decline} disabled={size <= MIN_SIZE} icon={<MinusOutlined />}>
                  Smaller
                </Button>
                <Button onClick={increase} disabled={size >= MAX_SIZE} icon={<PlusOutlined />}>
                  Larger
                </Button>
              </Button.Group>
            </Row>
            <br />

            <Row justify="center" align="middle">
              <div id="myqrcode">
                <QRCode
                  errorLevel="H"
                  size={size}
                  iconSize={size / 4}
                  value={`${webBaseUrl}/account/signup?storeId=${selectedValue}`}
                  icon={QRlogo}
                />

                <Button type="primary" size="small" onClick={downloadQRCode} className="nextSenderBtn">
                  Download
                </Button>
              </div>
            </Row>
            <Row justify="center" align="middle">
              <p>Store name: {selectedName}</p>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};
