import { Button, Col, Form, Row, Select } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetLocationDeliveryArea, GetProvinceDeliveryArea } from '@features/Common';
import { commonSelector } from '@features/Common/store/commonSelector';
import { Constants } from 'common/constants';
import { useHistory } from 'react-router';
import { PATHS } from '@routing/routes';
import { IslandRate, SourceRate } from 'common/code/DropdownCode';

export const DeliveryAreaRate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const commonState = useSelector(commonSelector);
  const [origin, setOrigin] = useState<string>('');
  const [island, setIsland] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [location, setLocation] = useState<string>('');

  const [rate, setRate] = useState<string>('');
  const [remarks, setRemark] = useState<string>('');
  useEffect(() => {
    if (origin && island) {
      dispatch(
        GetProvinceDeliveryArea({
          SourceCode: origin,
          IslandCode: island,
        })
      );
    }
  }, [origin, island]);

  useEffect(() => {
    form.resetFields(['Location']);
    form.resetFields(['Island']);
    form.resetFields(['Province']);
    setIsland('');
    setProvince('');
    setRemark('');
    setRate('');
  }, [origin]);

  useEffect(() => {
    form.resetFields(['Location']);
    form.resetFields(['Province']);
    setProvince('');
    setRemark('');
    setRate('');
  }, [island]);

  useEffect(() => {
    form.resetFields(['Location']);
    setRemark('');
    setRate('');
  }, [province]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (province) {
      setRemark('');
      setRate('');
      dispatch(
        GetLocationDeliveryArea({
          SourceCode: origin,
          IslandCode: island,
          Province: province,
        })
      );
    }
  }, [province]);

  useEffect(() => {
    if (commonState.DeliveryAreaRateLocation) {
      const foundItem = commonState.DeliveryAreaRateLocation.find(
        (map) =>
          map.IslandCode === island && map.SourceCode == origin && map.Location == location && map.Province == province
      );

      if (foundItem) {
        // The item with the desired location was found
        setRate(foundItem.Rate);
        setRemark(foundItem.Remarks);
      } else {
        console.log('Item not found');
      }
    }
  }, [location]);

  const onReset = () => {
    setOrigin('');
    setIsland('');
    setProvince('');
    setRemark('');
    setRate('');
    form.resetFields();
  };
  return (
    <>
      <br />
      <Form name="senderForm" layout="vertical" form={form}>
        <div className="">
          <Row justify="center" align="middle">
            <h1>Rate calculator</h1>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item label="Origin" name="Origin" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <Select
                  size="large"
                  showSearch
                  placeholder="Select origin"
                  optionFilterProp="children"
                  value={origin}
                  onChange={(value) => setOrigin(value)}
                  className="full-width"
                >
                  {SourceRate.map((destination) => (
                    <Select.Option key={destination.code} value={destination.code}>
                      {destination.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {origin && (
            <Row gutter={24} justify="center" align="middle">
              <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                <Form.Item label="Island" name="Island" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select island"
                    optionFilterProp="children"
                    value={island}
                    onChange={(value) => setIsland(value)}
                    className="full-width"
                  >
                    {IslandRate.map((destination) => (
                      <Select.Option key={destination.code} value={destination.code}>
                        {destination.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {island && (
            <Row gutter={24} justify="center" align="middle">
              {commonState.DeliveryAreaRate !== undefined && (
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Form.Item
                    label="Province"
                    name="Province"
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select province"
                      optionFilterProp="children"
                      value={province}
                      onChange={(value) => setProvince(value)}
                      className="full-width"
                    >
                      {commonState.DeliveryAreaRate.map((destination) => (
                        <Select.Option key={destination} value={destination}>
                          {destination}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}

          <Row gutter={24} justify="center" align="middle">
            {commonState.DeliveryAreaRateLocation !== undefined && province && (
              <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                <Form.Item label="City" name="Location" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select city"
                    optionFilterProp="children"
                    value={location}
                    onChange={(value) => setLocation(value)}
                    className="full-width"
                  >
                    {commonState.DeliveryAreaRateLocation.map((destination) => (
                      <Select.Option key={destination.Location} value={destination.Location}>
                        {destination.Location}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24} justify="center" align="middle">
            {rate && remarks && (
              <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                <Form.Item label="Details" name="Details">
                  <div className="border-sendus pl-10 pt-0 pb-0">
                    <p className="mb-0">
                      {' '}
                      <b className="textColor">Remarks:</b> {remarks}
                    </p>
                    <p className="mtp-0">
                      <b className="textColor">Rate:</b> ${rate}
                    </p>
                  </div>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row justify="center" align="middle">
            {/* <Col>
              <a className="normal-font" onClick={onReset}>
                Reset fields
              </a>
            </Col> */}
            <Col xs={24} sm={20} md={12} lg={8} xl={6}>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(PATHS.Home.path)}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" onClick={onReset}>
                Reset fields
              </Button>
            </Col>
          </Row>
          {/* <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Space direction="vertical" className="full-width textLeft">
                <Card title="Details" className="textLeft">
                  <p>
                    <b>Remarks:</b> {remarks}
                  </p>
                  <p>
                    <b>Rate:</b> {rate}
                  </p>
                </Card>
              </Space>
            </Col>
          </Row> */}
        </div>
      </Form>
      {/* <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row> */}
    </>
  );
};
