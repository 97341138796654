import React from 'react';
import { WebsiteFooter } from './Footer';
import { Col, Row } from 'antd';

export const TermsAndConditions = () => {
  return (
    <div>
      <h2 className="toc-margin">TERMS AND CONDITIONS</h2>

      <p>
        Goods are accepted for forwarding by Kalinga Freight Solutions Pty Ltd (<b>Company</b>) exclusively under the
        specified terms and conditions. These conditions are deemed to be an integral part of any contract established
        between the Company and individuals forwarding goods. No employee or agent of the Company or the Sender has the
        authority to waive or alter the terms of this contract.
      </p>

      <h3>1. FORWARDING AGENTS NOT CARRIERS</h3>
      <div className="subContentTOC">
        <p>1.1 The Company serves as a forwarding agent and is not a carrier.</p>
        <p>
          1.2 The Company assumes liability solely for damages to goods resulting from intentional neglect or default
          while the goods are physically in the custody of the Company or its employees. However, such liability is
          applicable only if the Company receives a claim notice for damages within 7 days from the Sender or the
          ultimate recipient of the goods following the occurrence of damage.
        </p>
        <p>1.3 Without detracting from clause 1.2, the Company disclaims responsibility for:</p>
        <ul className="ul-TOC">
          <li className="ul-TOC1">
            <p>
              (i) Any accident or any act, neglect, or default, whether deliberate or otherwise, by its agents or those
              with whom it contracts for forwarding goods, including carriers by land, sea, or air (such as shop owners,
              lighter men, canal, railway, or aircraft operators), warehouse keepers, or other individuals. This
              includes any money paid or remitted by the Company on behalf of the Senders for forwarding goods, whether
              for duties, charges, or other purposes. The general and special exemptions in this contract apply, even if
              the rates or charges by the Company differ from the amounts paid to agents, contractors, or other
              individuals.{' '}
            </p>
          </li>
          <li>
            <p>
              (ii) The Company forwards goods based on the contracts, terms, conditions, and regulations of the various
              individuals, companies, or authorities in possession of the goods. The Sender acknowledges that these
              entities may exclude or limit their liability within the bounds allowed by law, both to the Company and
              the consignor.
            </p>
          </li>
          <li>
            <p>
              (iii) The Company is not liable for loss or damage to goods if its agents, carriers, or other parties in
              possession of the goods at the time of loss or damage are not responsible.
            </p>
          </li>
          <li>
            <p>
              (iv) Goods of a fragile nature (e.g., glass, china, statuary, precious metals, or pictures) and perishable
              items (e.g., plants, foodstuffs, or provisions), as well as special goods like live animals, are forwarded
              at the owner&apos;s risk.
            </p>
          </li>
        </ul>
      </div>
      <h3>2. DECLARATION FORM</h3>
      <div className="subContentTOC">
        <p>
          2.1 The Sender is required to complete and submit to the Company or its agents a declaration form, providing
          details of the goods, their values, weights, and any other information as specified in the form.
        </p>
        <p>
          2.2 The Sender undertakes to cover all lawful and customary charges in the event that the consignee or the
          recipient of the goods rejects receipt (whether lawfully or unlawfully) or declines to pay the charges on the
          goods for any reason.
        </p>
        <p>
          2.3 The Sender guarantees that they are either the owners or authorized agents of the owners of the goods.
          Furthermore, all information filled out on any declaration form is accurate and compliant with applicable laws
          governing the transportation of goods.
        </p>
        <p>
          2.4 The Sender affirms that the declared value of the goods is precise and mirrors the current market value of
          the goods.
        </p>
      </div>

      <h3>3. GOODS INELIGIBLE FOR TRANSPORT</h3>
      <div className="subContentTOC">
        <p>
          (i) Currency, precious metals, precious stones, firearms, ammunition, money orders, drugs, explosives, toy
          guns, traveler’s cheques, or perishable items.
        </p>
        <p>(ii) Items subject to export or import restrictions in the country of origin and destination.</p>
        <p>
          (iii) Property whose transportation is prohibited by law, regulation, or state or local government in any
          country through which the shipment may be carried.
        </p>
        <p>(iv) Hazardous materials and dangerous goods.</p>
        <p>
          (v) Any other goods deemed, at the Company’s discretion, unsafe or unlawful for shipment or transportation.
        </p>
      </div>
      <h3>4. RATES</h3>
      <div className="subContentTOC">
        <p>
          4.1 The rates published by the Company are for the transport to the Country stated of goods consisting of
          ordinary merchandise without any special packing or storage requirements.
        </p>
        <p>
          4.2 The Sender is responsible for the payment of any increase in rates, freights, premiums or other charges
          which may be imposed after the commencement of the transit.
        </p>
        <p>
          4.3 The Sender acknowledges and agrees that customs duties, local taxes and charges, handling fees, and local
          delivery expenses are additional to the rates for carriage unless otherwise stated. All rates and charges when
          payable abroad are liable to be slightly increased due to exchange rate movements in the price of carriage and
          other services associated with the transport and customs clearance of the goods and other factors beyond the
          control of the Company.
        </p>
      </div>
      <h3>5. DELIVERY</h3>
      <div className="subContentTOC">
        <p>
          5.1 The Company will make every effort to transport and deliver acceptable goods to their destinations as
          promptly as possible, given the circumstances outlined in this contract. The Company is not obligated to
          notify the Sender of the potential or actual existence of any circumstance.
        </p>
        <p>
          5.2 The Company shall not be held responsible for delays arising from events or circumstances beyond its
          control. These may include, but are not limited to, delays resulting from the Sender&apos;s failure to furnish
          accurate delivery address information, customs clearance or other regulatory procedures, including issues
          arising from incorrect, incomplete, or missing customs documentation. Delays may also occur due to the
          Company&apos;s adherence to its policies regarding the payment of duties and taxes.
        </p>
        <p>
          5.3 The Company mandates all consignees to present sufficient proof of identification deemed acceptable by the
          Company. However, the Company is not accountable if the goods are delivered to a person providing false or
          inadequate identification or to someone other than the consignee named.
        </p>
      </div>
      <h3>6. GOODS SUBJECT TO LIEN</h3>
      <div className="subContentTOC">
        <p>
          6.1 The Company retains a comprehensive lien on all goods received and retained by them, encompassing all
          freight charges, customs duties, advances, or any other charges arising from the transportation covered by
          this agreement.
        </p>
        <p>
          6.2 The Company reserves the right to withhold the surrender of goods until the aforementioned charges are
          settled.
        </p>
        <p>
          6.3 The Sender acknowledges that if the general lien remains unsatisfied for an extended period from the
          initial receipt of goods, the Company may proceed to auction or conduct a private treaty sale of the goods.
          The proceeds from such a sale will be utilized to settle the lien and associated expenses.
        </p>
      </div>
      <h3>7. ROUTE</h3>
      <div className="subContentTOC">
        <p>
          Unless otherwise specified by an agreement or directive from the Sender, the Company has the authority to
          exercise its discretion in determining the route and mode of transmission. Additionally, the Company may
          independently decide on declaring the value or interest for the purpose of any bill of lading, document of
          title, or any other relevant purpose.
        </p>
      </div>
      <h3>8. AUTHORITY TO SELL UNDECLARED GOODS</h3>
      <div className="subContentTOC">
        <p>
          8.1 The Sender acknowledges that perishable goods, if refused by the consignee or undeliverable for reasons
          beyond the control of the Company, may be sold without prior notice to the Sender. The Sender is responsible
          for any costs or charges incurred exceeding the proceeds, if any. The determination by the Company regarding
          the perishable nature of the goods is binding and may be made at any time.
        </p>
        <p>
          8.2 For non-perishable goods that are refused or undeliverable, the Company may sell them after sending notice
          via post, electronic mail, or any communication method known to be used by the Sender, informing the Sender of
          the non-delivery and seeking instructions on the goods&apos; disposal. If, within 14 days of sending the
          notice, the Company does not receive instructions, the Sender is liable for the costs and charges associated
          with carrying out instructions. In cases where the goods are sold under the aforementioned authority, the
          Sender is responsible for any costs and charges exceeding the proceeds, if any.
        </p>
      </div>

      <h3>9. INSURANCE</h3>
      <div className="subContentTOC">
        <p>
          9.1 The Sender acknowledges that the Company secures insurance only under the terms and exceptions mutually
          agreed upon by the Company, underwriters, or insurers covering the risk.
        </p>
        <p>
          9.2 In instances where the Company opts to provide insurance, it is not obligated to arrange separate
          insurance but may declare the goods under any open general policy.
        </p>
        <p>
          9.3 The Sender agrees not to seek recourse against the Company for any claims arising under or in connection
          with any insurance procured by the Company on behalf of the Sender.
        </p>
      </div>
      <h3>10. DUTIES AND TAXES</h3>
      <div className="subContentTOC">
        <p>
          10.1 All shipments traversing international borders must undergo customs clearance in the destination country
          before reaching the recipient.
        </p>
        <p>
          10.2 The Sender bears sole responsibility for all duties, taxes, and other custom requirements associated with
          the shipment.
        </p>
        <p>
          10.3 In the event that customs or other agencies detain any shipments, the Company may make an effort to
          notify the recipient or the Sender. If the shipment is deemed undeliverable, the Company holds no
          responsibility for the inability to deliver or any resulting delays.
        </p>
      </div>
      <h3>11. CLAIMS</h3>
      <div className="subContentTOC">
        <p>
          11.1 Except as specified in clause 1.2, any claims must be initiated by the Sender and submitted in writing to
          the Company&apos;s nearest office to the location where the shipment was accepted within 30 days from the date
          of delivery to the destination. Claims against the Company are precluded beyond this timeframe.
        </p>
        <p>
          11.2 No claims for loss or damage will be processed until all transportation charges have been settled, and no
          amount from any claim shall be set off against transportation charges owed to the Company.
        </p>
        <p>
          11.3 In the event of claim settlements, the Company will not reimburse the fundamental costs of shipment,
          pick-up, and delivery.
        </p>
        <p>11.4 The Sender is not entitled to file claims for consequential loss.</p>
      </div>
      <h3>12. PRIVACY AND CONFIDENTIALITY</h3>
      <div className="subContentTOC">
        <p>
          12.1 The Company values the privacy of the Sender and commits not to disclose the Sender&apos;s details or any
          information pertaining to the Sender or the shipment without the Sender&apos;s authorization, except for:
        </p>
        <ul className="ul-TOC">
          <li>
            <p>
              (i) Providing details of the Sender and the shipment to its employees, agents, and contractors involved in
              the shipment of the goods.
            </p>
          </li>
          <li>
            <p>(ii) Disclosing relevant information to customs authorities and other entities as mandated by law.</p>
          </li>
        </ul>
        <p>
          12.2 The Sender acknowledges that the Company and its employees are authorized to access information about the
          Sender and the shipped goods solely for the purpose of the shipment or as required by law, and not for any
          other purpose.
        </p>
      </div>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </div>
  );
};
