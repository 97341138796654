import { Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { PATHS } from '@routing/routes';
import { WebsiteFooter } from './Footer';
import { KFSBoxDesign, KFSBoxDesign2, KFSboxSize } from 'common/images';
export const Balikbayanbox = () => {
  return (
    <>
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>{PATHS.Balikbayanbox.displayTitle}</h3>
        </Col>
      </Row>
      <div className="bodyAboutUs">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p>
              Presenting our meticulously crafted balikbayan box – a container that goes beyond being a mere package,
              creating a heartfelt connection. Enhance your sending experience by adding a personal message because, at
              KFS Balikbayan Box, we are dedicated to delivering more than just parcels.
            </p>
            <p>
              These boxes are crafted with care by Opal and designed with environmental sustainability in mind. These
              durable and spacious boxes are perfect for sending all the goodies and surprises to your loved ones in the
              Philippines.
            </p>
          </Col>
        </Row>
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img src={KFSBoxDesign} alt="Header" className="full-width" />
          </Col>
        </Row>
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img src={KFSboxSize} alt="Header" className="full-width" />
          </Col>
        </Row>
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img src={KFSBoxDesign2} alt="Header" className="full-width" />
          </Col>
        </Row>
        <br />
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <WebsiteFooter />
          </Col>
        </Row>
      </div>
    </>
  );
};
