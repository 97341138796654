import { Breadcrumb, Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingActions } from '../store/BookingSlice';
import { BookingSelector } from '../store/BookingSelector';

import { SenderDetailsSelector } from '@features/SenderDetails';
import { ReceiverSelector } from '@features/Receiver';

import { Constants } from 'common/constants';
import { useEffect } from 'react';
import { TransactionSelector } from '@features/Transaction';
import styles from 'features/Booking/Booking.module.less';
export const Perks = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const receiverState = useSelector(ReceiverSelector);
  const transactionState = useSelector(TransactionSelector);
  useEffect(() => {
    if (!transactionState.FromUpdateLink) {
      if (
        !senderDetailsState.Sender === null ||
        senderDetailsState.Sender === undefined ||
        receiverState.Receiver === null ||
        receiverState.Receiver === undefined ||
        bookingState.BoxContent === null ||
        bookingState.BoxContent === undefined ||
        bookingState.BoxDetails === null ||
        bookingState.BoxDetails === undefined
      ) {
        history.push('/home');
      }
    }
    // alert(transactionState.TransactionSummary?.BoxDetails.Date);
  }, [bookingState, history]);

  const onFinish = async (values: any) => {
    // dispatch(
    //   BookingActions.SetPerks({
    //     ...values,
    //   })
    // );
    history.push('/booking/declaration');
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        <h1>Some perks for you</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {!transactionState.FromUpdateLink && (
              <Breadcrumb
                items={[
                  {
                    title: 'Sender',
                  },
                  {
                    title: 'Sender address',
                  },
                  {
                    title: 'Receiver',
                  },
                  {
                    title: 'Box content',
                  },
                  {
                    title: 'Pick up and box details',
                  },
                  {
                    title: 'Perks for you',
                  },
                ]}
              />
            )}
            <br />
            {/* <Form.Item initialValue={10} label="Sign up bonus" name="SignUpBonus"> */}
            <Form.Item
              initialValue={10}
              label="Sign up bonus"
              // label={
              //   bookingState.ReferralResponse?.IsFirstTransaction && !bookingState.ReferralResponse?.IsFirstTransaction
              //     ? 'Sign up bonus'
              //     : `Thank you for referring ${bookingState.ReferralResponse?.Referee} to us. Enjoy your bonus.`
              // }
              name="SignUpBonus"
            >
              <InputNumber
                readOnly
                size="large"
                className="full-width"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            {bookingState.ReferralResponse?.IsReferred && (
              <Form.Item
                initialValue={bookingState.ReferralResponse?.Referrer}
                label={
                  <div>
                    <span>Do you have a referral code? </span>
                    <br />
                    <span className={styles.perksSmallRef}>
                      Provide the email address of the person who referred you.
                    </span>
                  </div>
                }
                name="ReferralCode"
                rules={[
                  // { required: true, message: Constants.REQUIRED_FIELD },
                  {
                    pattern: Constants.EMAIL_REGEX_INPUT,
                    message: 'Please enter a valid email address.',
                  },
                ]}
              >
                <Input size="large" maxLength={150} readOnly />
              </Form.Item>
            )}
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() =>
                  history.push(!transactionState.FromUpdateLink ? '/booking/boxdetails' : '/transaction/summary')
                }
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {transactionState.FromUpdateLink ? Constants.BTN_UPDATE : Constants.BTN_CONTINUE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
