import { Card, Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
import { KFS_Store_Warnambool2, KFS_Store_Warnambool3, KFSStoreN1, KFSStoreN2, KFSStoreN3 } from 'common/images';
import { useHistory } from 'react-router';
import { PATHS } from '@routing/routes';
export const StorePartnerWarnambool = () => {
  const history = useHistory();

  const handleMelbourne = () => {
    history.push(PATHS.KFSStorePartnerMel.path);
  };

  const handleSydney = () => {
    history.push(PATHS.KFSStorePartnerSyd.path);
  };

  const handleBendigo = () => {
    history.push(PATHS.KFSStorePartnerBendigo.path);
  };

  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextFAQs">KFS store partners</p>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col sm={24} className="">
          <div className="">
            <a className="normal-font dInlineBlock" onClick={() => handleMelbourne()}>
              <h3 className="normalfontweight melsydlbl"> Melbourne </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleBendigo()}>
              <h3 className="normalfontweight melsydlbl"> Bendigo </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock underline">
              <h3 className="normalfontweight melsydlbl"> Warrnambool </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleSydney()}>
              <h3 className="normalfontweight melsydlbl"> Sydney</h3>
            </a>
          </div>
        </Col>
      </Row>
      <a className="textColor">Grab your KFS balikbayan boxes from our store partners near you.</a>
      <br />
      <br />
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Warnambool3} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Tindahan ni Tina </h3>
              <p className="textColor mtb"> Timboon- Nullawarre Rd, Nullawarre, VIC 1940</p>
              <p className="textColor mtp-0">+61 481 114 935</p>
              <p className="textColor mtp-0">
                <b>Areas covered</b>
                <ul className="mtp-0">
                  <li>3280 - Warrnambool</li>
                  <li>
                    3268 - Ayrford, Brucknell, Cooriemungle, Cowleys Creek, Curdie Vale, Curdies River, Heytesbury
                    Lower, Newfield, Nirranda, Nirranda East, Nirranda South, Nullawarre, Nullawarre East, Paaratte, The
                    Cove, Timboon, Timboon West
                  </li>
                  <li>3250 - Colac</li>
                  <li>3264 - Terang</li>
                  <li>3265 - Garvoc, Panmure</li>
                  <li>3266 - Cobden, Glenfyne, Simpson</li>
                  <li>3267 - Scotts Creek</li>
                  <li>3269 - Port Campbell, Princetown</li>
                  <li>3272 - Mortlake</li>
                  <li>3277 - Naringal</li>
                  <li>3282 - Koroit</li>
                  <li>3284 - Port Fairy</li>
                </ul>
              </p>
              {/* <p className="textColor mtp-0">
                <b> Areas covered: </b>3280 - Warrnambool. 3268 - Ayrford, Brucknell, Cooriemungle, Cowleys Creek,
                Curdie Vale, Curdies River, Heytesbury Lower, Newfield, Nirranda, Nirranda East, Nirranda South,
                Nullawarre, Nullawarre East, Paaratte, The Cove, Timboon, Timboon West. 3250 - Colac. 3264 - Terang.
                3265 - Garvoc, Panmure. 3266 - Cobden, Glenfyne, Simpson. 3267 - Scotts Creek .3269 - Port Campbell,
                Princetown. 3272 - Mortlake. 3277 - Naringal. 3282 - Koroit. 3284 - Port Fairy
              </p> */}
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Warnambool2} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Asian Station Grocery </h3>
              <p className="textColor mtb">98 Liebig Street, Warrnambool, VIC 3280</p>
              <p className="textColor mtp-0">+61 3 5561 0655</p>
            </div>
          </Card>
        </Col>
      </Row>

      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
