import { Col, Row, Space } from 'antd';
import { FaFacebook, FaInstagram, FaAddressCard, FaPhone, FaRegClock } from 'react-icons/fa';
import '@features/Webpage/homepage.css';
import { Link } from 'react-router-dom';
import { PATHS } from '@routing/routes';
export const WebsiteFooter = () => {
  return (
    <>
      <div className="footer-bck">
        <Row gutter={0} align="middle" className="textColor">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <p>Connect with us</p>
            <Col xs={24} sm={24} md={12} lg={8} xl={4}>
              <Space>
                <a
                  href="https://www.facebook.com/profile.php?id=61553152930784"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="contact-us-icon" />
                </a>
                <a href="https://www.instagram.com/kfsbalikbayanbox/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="contact-us-icon" />
                </a>
              </Space>
            </Col>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <p className="textColor aboutVM">
              <Link to="/privacynotice">Privacy</Link> |{' '}
              <Link to={PATHS.TermsAndConditions.path}>Terms and Conditions</Link> |{' '}
              <Link to={PATHS.FAQs.path}>FAQs</Link> | <Link to={PATHS.ContactUs.path}>Contact us</Link>
            </p>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <p className="textColor aboutVM">&copy; Kalinga Freight Solutions Pty Ltd (KFS) 2023 ABN 80 672 974 515</p>
          </Col>
        </Row>
      </div>
    </>
  );
};
