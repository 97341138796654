import { PATHS } from '@routing/routes';
import { Constants } from 'common/constants';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

export const LogoutRedirect = async () => {
  ClearCache();
  window.location.href = PATHS.Logout.path;
};

export const ClearCache = () => {
  cookies.remove(Constants.Token, { path: PATHS.Login.path });
  cookies.remove(Constants.IdToken, { path: PATHS.Login.path });
  cookies.remove(Constants.RefreshToken, { path: PATHS.Login.path });
};
