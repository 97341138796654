import { Card, Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
import {
  KFSStoreN1,
  KFSStoreN2,
  KFSStoreN3,
  KFSStoreSE1,
  KFSStoreSE2,
  KFSStoreSE3,
  KFSStoreW1,
  KFSStoreW2,
  KFSStoreW3,
  KFSStoreW4,
  KFSStoreW5,
  KFSStoreW6,
  KFSStoreSE5,
  KFSStoreSE6,
  KFSStoreSE4,
  KFS_Store_Mel,
  KFS_Store_SE16,
  KFS_Store_MelBalikbayan,
  MEL_Sampaguita,
} from 'common/images';
import { useHistory } from 'react-router';
import { PATHS } from '@routing/routes';
export const StorePartnerMel = () => {
  const history = useHistory();
  const handleCitySelect = () => {
    history.push(PATHS.KFSStorePartnerSyd.path);
  };

  const handleBendigo = () => {
    history.push(PATHS.KFSStorePartnerBendigo.path);
  };

  const handleWarnambool = () => {
    history.push(PATHS.KFSStorePartnerWarnambool.path);
  };
  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextFAQs">KFS store partners</p>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col sm={24} className="">
          <div className="">
            <a className="normal-font dInlineBlock underline">
              <h3 className="normalfontweight melsydlbl"> Melbourne </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleBendigo()}>
              <h3 className="normalfontweight melsydlbl"> Bendigo </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleWarnambool()}>
              <h3 className="normalfontweight melsydlbl"> Warrnambool </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleCitySelect()}>
              <h3 className="normalfontweight melsydlbl"> Sydney</h3>
            </a>
          </div>
        </Col>
      </Row>
      <a className="textColor">Grab your KFS balikbayan boxes from our store partners near you.</a>

      <h3>North</h3>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreN3} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Inang’s Asian Mart</h3>
              <p className="textColor mtb">Shop 8 342/338 McDonalds Rd, South Morang VIC 3752</p>
              <p className="textColor mtp-0">+61 3 9424 6726</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreN2} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Pinoy Tayo Asian Grocery</h3>
              <p className="textColor mtb">
                Craigieburn Central, Shop c-0012, Woolworths Quadrant, 340 Craigieburn Rd, Craigieburn VIC 3064
              </p>
              <p className="textColor mtp-0">+61 413 996 887</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreN1} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Tindahan Filipino Asian Grocery</h3>
              <p className="textColor mtb">Shop 10/22 McKimmies Rd, Lalor VIC 3075</p>
              <p className="textColor mtp-0">+61 3 9464 7474</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_MelBalikbayan} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Balikbayan Groceries</h3>
              <p className="textColor mtb">48 Stubbs St, Kensington VIC 3031</p>
              <p className="textColor mtp-0">+61 3 9086 3731</p>
            </div>
          </Card>
        </Col>
      </Row>
      <h3>West</h3>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreW1} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Super Pinoy Groceries</h3>
              <p className="textColor mtb">5 Market Pl, Braybrook VIC 3019</p>
              <p className="textColor mtp-0">+61 3 9311 6147</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreW2} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Ma &quot;Swerte&quot; Filipino, Asian & Money Transfer</h3>
              <p className="textColor mtb">475 Ballarat Rd, Sunshine VIC 3020</p>
              <p className="textColor mtp-0">+61 3 9939 4210</p>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreW4} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Amelia&apos;s Variety Store</h3>
              <p className="textColor mtb"> 9/161-165 Railway Ave, Laverton VIC 3028</p>
              <p className="textColor mtp-0">+61 3 9369 2393</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreW5} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Happy Hut</h3>
              <p className="textColor mtb">85-87 Oakwood Rd, Albanvale VIC 3023</p>
              <p className="textColor mtp-0">+61 3 8390 4212</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreW6} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Asian Pantry</h3>
              <p className="textColor mtb">13/119 Hopkins St, Footscray VIC 3011</p>
              <p className="textColor mtp-0">+61 400 962 441</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreW3} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">Super Pinoy Filipino Groceries</h3>
              <p className="textColor mtb">Shop 5/20-22 E Esplanade, St Albans VIC 3021</p>
              <p className="textColor mtp-0">+61 3 9366 1243</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Mel} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor  mtb-0 mtp-0">West Side Filipino Asian Grocery </h3>
              <p className="textColor mtb">17/127 Gourlay Rd, Taylors Hill VIC 3037</p>
              <p className="textColor mtp-0">+61 413 866 020</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={MEL_Sampaguita} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Sampaguita Sari-Sari Store</h3>
              <p className="textColor mtb">51/53 Synnot St, Werribee VIC 3030</p>
              <p className="textColor mtp-0">+61 3 9741 4414</p>
            </div>
          </Card>
        </Col>
      </Row>
      <h3>South East</h3>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreSE1} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Sari Sari Asian Groceries</h3>
              <p className="textColor mtb">26A Webb St, Narre Warren VIC 3805</p>
              <p className="textColor mtp-0">+61 3 9796 0880</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreSE2} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Sari Sari Asian Groceries Pakenham</h3>
              <p className="textColor mtb">10/19 - 27 Lakeside Blvd, Pakenham VIC 3810</p>
              <p className="textColor mtp-0">+61 3 5940 3862</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreSE3} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Filipino & Asian Groceries</h3>
              <p className="textColor mtb">56 Spring Square, Hallam VIC 3803</p>
              <p className="textColor mtp-0">+61 433 729 089</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreSE4} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">
                Philside Pilipino Groceries & Convenience Store (Cravings Filo & AsianMart)
              </h3>
              <p className="textColor mtb">645-649 Warrigal Rd, Chadstone VIC 3148</p>
              <p className="textColor mtp-0">+61 468626522</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreSE5} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Mrs Wan&apos;s Asian Mart</h3>
              <p className="textColor mtb">Shop, 3/47 High St, Cranbourne VIC 3977</p>
              <p className="textColor mtp-0">+61 3 7023 6388</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFSStoreSE6} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">One Playzone</h3>
              <p className="textColor mtb">Unit 14 & 21/10 Assembly Dr, Dandenong South VIC 3175</p>
              <p className="textColor mtp-0">+61 3 9795 5512</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_SE16} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Manila Grocers</h3>
              <p className="textColor mtb">54 Selenium Wy, Clyde North VIC 3978</p>
              <p className="textColor mtp-0">+61 3 8795 7670</p>
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
