import { Alert, Button, Col, Form, Input, Row, Select, Space, Steps } from 'antd';
import { useDispatch } from 'react-redux';
import { Constants } from 'common/constants';
import TextArea from 'antd/es/input/TextArea';
import { FaFacebook, FaInstagram, FaAddressCard, FaPhone, FaRegClock } from 'react-icons/fa';
import { contactusheader } from 'common/images';
import '@features/Webpage/homepage.css';
import { EnquiryCodeList } from 'common/code/ContactUsCode';
import { ContactUsSave } from '../business/WebpageBusiness';
import { useEffect, useState } from 'react';
import { WebsiteFooter } from './Footer';
import { PostStart } from '@features/Common';
export interface ContactViewModel {
  Enquiry: string;
  EnquiryText: string;
  Name: string;
  Email: string;
  ContactNumber: string;
  Message: string;
}
export const ContactUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PostStart());
  }, []);
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const [showAlert, setShowAlert] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const handleEnquiryChange = (value) => {
    const selectedEnquiry = EnquiryCodeList.find((eq) => eq.code === value);
    setSelectedText(selectedEnquiry ? selectedEnquiry.name : '');
  };
  const onFinish = async (values: ContactViewModel) => {
    const updatedValues: ContactViewModel = {
      ...values,
      EnquiryText: selectedText,
    };
    await dispatch(ContactUsSave(updatedValues));
    setSelectedText('');
    setShowAlert(true);
    form.resetFields();
  };
  const [form] = Form.useForm();

  const onclick = () => {
    setShowAlert(false);
  };
  const onReset = () => {
    setShowAlert(false);
    form.resetFields();
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  return (
    <div className="App">
      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <div className="border-sendus">
          <Row justify="center" align="middle">
            <h1>Send us a message</h1>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item label="Enquiry" name="Enquiry" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <Select
                  size="large"
                  showSearch
                  placeholder="Select enquiry"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={handleEnquiryChange}
                >
                  {EnquiryCodeList.map((eq) => (
                    <Select.Option key={eq.code} value={eq.code}>
                      {eq.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item label="Name" name="Name" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <Input size="large" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: Constants.REQUIRED_FIELD },
                  { type: 'email', message: Constants.ERROR_EMAIL_FORMAT },
                ]}
              >
                <Input size="large" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Item
                label="Contact number"
                name="ContactNumber"
                rules={[
                  { required: true, message: Constants.REQUIRED_FIELD },
                  {
                    pattern: /^(\+[0-9]+|[0-9]+)$/,
                    message: 'Please enter a valid contact number',
                  },
                ]}
              >
                <Input size="large" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item label="Message" name="Message" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <TextArea size="large" maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
          {showAlert && (
            <>
              <Row gutter={24} justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <Alert
                    message="We have received your message. We will respond to you shortly."
                    type="success"
                    showIcon
                    closable
                    onClose={handleAlertClose}
                  />
                </Col>
              </Row>
              <br />
            </>
          )}
          <Row gutter={24} justify="center" align="middle">
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <Button className="prevSenderBtn" size="large" type="default" onClick={onReset}>
                Clear
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit" onClick={onclick}>
                Submit
              </Button>
            </Col>
          </Row>
        </div>
        <br />

        <div className="border-sendus">
          <Row justify="center" align="middle">
            {' '}
            <h1>Our contact information</h1>
          </Row>
          <Row gutter={24} justify="center" align="middle">
            {/* <Col xs={24} sm={24} md={12} lg={8} xl={4}>
              <Space>
                <FaFacebook className="contact-us-icon" />
                <p className="textColor">KFS Balikbayan Box</p>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={4}>
              <Space>
                <FaInstagram className="contact-us-icon" />
                <p className="textColor">kfsbalikbayanbox</p>
              </Space>
            </Col> */}
            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
              <Space>
                <FaAddressCard className="contact-us-icon" />
                <p className="textColor">info@kfsbox.com</p>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={5}>
              <Space>
                <FaPhone className="contact-us-icon" />
                <p className="textColor">03 9969 2205 | 0413 275 288</p>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={4}>
              <Space>
                <FaRegClock className="contact-us-icon" />
                <p className="textColor">Hours: 9AM to 8PM</p>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </div>
  );
};
