import { PATHS } from '@routing/routes';
import { Button, Form } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
export const BackOfficePH = () => {
  const history = useHistory();

  return (
    <Form.Item>
      <Button
        type="default"
        icon={<TableOutlined />}
        size="large"
        block
        className="bold left-icon-button main-menu-option site-form-item-icon"
        onClick={() => history.push(PATHS.ContainerListing.path)}
      >
        <div className="menu-small-320">Container management</div>
      </Button>
    </Form.Item>
  );
};
