interface EnquiryCode {
  name: string;
  code: string;
}

export const EnquiryCodeList: EnquiryCode[] = [
  {
    name: 'Balikbayan box',
    code: 'BB',
  },
  {
    name: 'Returning residents',
    code: 'RR',
  },
  {
    name: 'Business freight solutions',
    code: 'BFS',
  },
  {
    name: 'Request for an empty box',
    code: 'REB',
  },
  {
    name: 'Others',
    code: 'Others',
  },
];
