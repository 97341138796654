import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import WebSessionStorage from 'redux-persist/lib/storage/session';
import { connectRouter } from 'connected-react-router';

import commonReducer from 'features/Common/store/commonSlice';

import loginReducer from '@features/Login/store/loginSlice';

import bookingReducer from '@features/Booking/store/BookingSlice';

import receiverReducer from '@features/Receiver/store/ReceiverSlice';
import senderDetailsReducer from '@features/SenderDetails/store/SenderDetailsSlice';
import trackingReducer from '@features/Tracking/store/TrackingSlice';
import transactionReducer from '@features/Transaction/store/TransactionSlice';
import invoiceReducer from '@features/Invoice/store/InvoiceSlice';
import barcodeReducer from '@features/Barcode/store/BarcodeSlice';
import accountReducer from '@features/Account/store/AccountSlice';
import incentiveReducer from '@features/Incentive/store/IncentiveSlice';
import orderBoxReducer from '@features/OrderBox/store/OrderBoxSlice';
import employeeReducer from '@features/Employee/store/EmployeeSlice';
import containerReducer from '@features/Container/store/ContainerSlice';
import interStateReducer from '@features/InterState/store/InterStateSlice';
import storeReducer from '@features/Stores/store/StoresSlice';
export const persistConfig = {
  key: `${process.env.REACT_APP_NAME}-app-root`,
  storage: WebSessionStorage,
};

export const rootReducer = (history) =>
  persistReducer(
    persistConfig,
    combineReducers({
      router: connectRouter(history),
      common: commonReducer,
      login: loginReducer,
      Booking: bookingReducer,
      Receiver: receiverReducer,
      SenderDetails: senderDetailsReducer,
      Tracking: trackingReducer,
      Transaction: transactionReducer,
      Invoice: invoiceReducer,
      Barcode: barcodeReducer,
      Account: accountReducer,
      Incentive: incentiveReducer,
      OrderBox: orderBoxReducer,
      Employee: employeeReducer,
      Container: containerReducer,
      InterState: interStateReducer,
      Stores: storeReducer,
    })
  );

export const persistedReducer = (history) => rootReducer(history);
