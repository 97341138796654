import { RouteConfig } from 'react-router-config';
// import { Logout } from '@features/Login/components/Logout';
import { Home } from '@features/Home/components/Home';

import { Login } from '@features/Login/components/Login';

import { Sender } from '@features/Booking/components/Sender';
import { SenderAddress } from '@features/Booking/components/SenderAddress';
import { Receiver } from '@features/Booking/components/Receiver';
import { BoxContent } from '@features/Booking/components/BoxContent';
import { BoxDetails } from '@features/Booking/components/BoxDetails';
import { Summary } from '@features/Booking/components/Summary';
import { Confirmation } from '@features/Booking/components/Confirmation';

import { SignUp } from '@features/Account/components/Signup';
import { ResetPassword } from '@features/Account/components/ResetPassword';
import { PasswordReset } from '@features/Account/components/PasswordReset';
import { ChangePassword } from '@features/Account/components/ChangePassword';
import { Listing } from '@features/Booking/components/Listing';
import { SenderListing } from '@features/SenderDetails/components/SenderListing';
import { Tracking } from '@features/Tracking/components/Tracking';
import { SenderDetails } from '@features/SenderDetails/components/SenderDetails';
import { Declaration } from '@features/Booking/components/Declaration';
import { SenderAdd } from '@features/SenderDetails/components/SenderAdd';
import { ReceiverListing } from '@features/Receiver/components/ReceiverListing';
import { ReceiverDetails } from '@features/Receiver/components/ReceiverDetails';
import { Transaction } from '@features/Transaction/components/Transaction';
import { Barcode } from '@features/Booking/components/Barcode';
import { Invoice } from '@features/Invoice/components/Invoice';
import { GenerateBarcode } from '@features/Barcode/components/Generate';
import { SummaryTransaction } from '@features/Transaction/components/SummaryTransaction';
import { BookingSignature } from '@features/Booking/components/BookingSignature';
import { TrackingStatus } from '@features/Tracking/components/TrackingStatus';
import { AccountNotConfirm } from '@features/Account/components/AccountNotConfirm';
import { Homepage } from '@features/Webpage/components/Homepage';
import { Service } from '@features/Webpage/components/Service';
import { ContactUs } from '@features/Webpage/components/ContactUs';
import { AboutUs } from '@features/Webpage/components/AboutUs';
import { ConfirmAccount } from '@features/Account/components/ConfirmAccount';
import { Perks } from '@features/Booking/components/Perks';
import { Referral } from '@features/Incentive/component/Referral';
import { TermsAndCondition } from '@features/Webpage/components/TermsAndCondition';
import { TermsAndConditionRewards } from '@features/Webpage/components/TermsAndConditionRewards';
import { PrivacyNotice } from '@features/Webpage/components/PrivacyNotice';
import { Balikbayanbox } from '@features/Webpage/components/Balikbayanbox';
import { FAQs } from '@features/Webpage/components/FAQs';
import { PricingMel } from '@features/Webpage/components/PricingMel';
import { OrderBox } from '@features/OrderBox/components/OrderBox';
import { OrderABoxConfirmation } from '@features/OrderBox/components/OrderABoxConfirmation';
import { SenderListingOrderBox } from '@features/OrderBox/components/SenderListingOrderBox';
import { SummaryOrderBox } from '@features/Transaction/components/SummaryOrderBox';
import SenderCommon from '@features/Common/component/SenderCommon';
import UnderConstructionPage from '@features/Common/component/UnderConstructionPage';
import SenderAddressCommon from '@features/Common/component/SenderAddressCommon';
import { OrderBoxSender } from '@features/OrderBox/components/OrderBoxSender';
import { OrderBoxAddress } from '@features/OrderBox/components/OrderBoxAddress';
import { SenderListingBooking } from '@features/Booking/components/SenderListingBooking';
import { ReceiverListingBooking } from '@features/Booking/components/ReceiverListingBooking';
import ReceiverCommon from '@features/Common/component/ReceiverCommon';
import { ContainerLoading } from '@features/Container/components/ContainerLoading';
import { ContainerProfile } from '@features/Container/components/ContainerProfile';
import { ContainerListing } from '@features/Container/components/ContainerListing';
import { DeliveryAreaRate } from '@features/Webpage/components/DeliveryAreaRate';
import { Delivered } from '@features/Booking/components/Delivered';
import { InterStateListing } from '@features/InterState/components/InterStateListing';
import { InterStateProfile } from '@features/InterState/components/InterStateProfile';
import { InterStateLoading } from '@features/InterState/components/InterStateLoading';
import { PackingList } from '@features/Container/components/PackingList';
import { StorePartnerMel } from '@features/Webpage/components/StorePartnerMel';
import { StorePartnerSyd } from '@features/Webpage/components/StorePartnerSyd';
import { PricingSyd } from '@features/Webpage/components/PricingSyd';
import { StoresIncentive } from '@features/Stores/component/StoresIncentive';
import { StorePartnerBendigo } from '@features/Webpage/components/StorePartnerBendigo';
import { StorePartnerWarnambool } from '@features/Webpage/components/StorePartnerWarnambool';
import TrackingManagement from '@features/Tracking/components/TrackingManagement';
import { TermsAndConditions } from '@features/Webpage/components/TermsAndConditions';

/**
 * A router that defines the paths and properties specific for routing in the entire application.
 *
 * == USAGE ==
 *
 * Define routes in the below setup by:
 *   - Specifying the path in the browser that, when navigated to, will render its
 *     corresponding component.
 *   - Function that returns the React component to be rendered, preferably a component
 *     that represents the page (top-level entry point) of the functionality.
 *
 * == FOR FUTURE DEVELOPMENT ==
 *
 * Note:
 *   The flat and long hierarchy of this routing setup may not scale when we need
 *   to manage many routes for every module. Hence, do feel free to modify the shape
 *   of this schema (e.g. add sub nodes / combine nested objects) to suit that scale.
 */

export const PATHS = {
  Home: {
    path: '/home',
    displayTitle: 'Home',
  },
  Login: {
    path: '/Login',
    displayTitle: 'Login',
  },
  TransactionSummary: {
    path: '/Transaction/summary',
    displayTitle: 'Summary',
  },
  Sender: {
    path: '/Booking/Sender',
    displayTitle: 'Sender',
  },
  SenderAddress: {
    path: '/Booking/SenderAddress',
    displayTitle: 'Sender address',
  },
  Receiver: {
    path: '/Booking/Receiver',
    displayTitle: 'Receiver',
  },
  BoxContent: {
    path: '/Booking/BoxContent',
    displayTitle: 'Box Content',
  },
  BoxDetails: {
    path: '/Booking/BoxDetails',
    displayTitle: 'Pick up and box details',
  },
  Summary: {
    path: '/Booking/Summary',
    displayTitle: 'Summary',
  },
  Confirmation: {
    path: '/Booking/Confirmation',
    displayTitle: 'Confirmation',
  },
  BookingListing: {
    path: '/Booking/Listing',
    displayTitle: 'Listing',
  },
  Signup: {
    path: '/Account/Signup',
    displayTitle: 'Signup',
  },
  ResetPassword: {
    path: '/Account/ResetPassword',
    displayTitle: 'Reset Password',
  },
  ResetConfirmation: {
    path: '/Account/PasswordReset',
    displayTitle: 'Password Reset',
  },
  ChangePassword: {
    path: '/Account/ChangePassword',
    displayTitle: 'Change Password',
  },
  ConfirmAccount: {
    path: '/Account/ConfirmAccount',
    displayTitle: 'Confirm Account',
  },
  Logout: {
    path: '/Login',
  },
  SenderListing: {
    path: '/Sender/Listing',
    displayTitle: 'Sender Listing',
  },
  SenderDetails: {
    path: '/Sender/Details',
    displayTitle: 'Sender Details',
  },
  Tracking: {
    path: '/Tracking',
    displayTitle: 'Tracking Listing',
  },
  Declaration: {
    path: '/Booking/Declaration',
    displayTitle: 'Declaration Listing',
  },
  SenderAdd: {
    path: '/Sender/Add',
    displayTitle: 'Sender Add',
  },
  ReceiverListing: {
    path: '/Receiver/Listing',
    displayTitle: 'Receiver Listing',
  },
  ReceiverDetails: {
    path: '/Receiver/Details',
    displayTitle: 'Receiver Details',
  },
  Transaction: {
    path: '/Transaction',
    displayTitle: 'Transaction',
  },
  Invoice: {
    path: '/Invoice',
    displayTitle: 'Invoice',
  },
  Barcode: {
    path: '/Booking/Barcode',
    displayTitle: 'Barcode',
  },
  BarcodeGenerate: {
    path: '/Barcode/Generate',
    displayTitle: 'Generate',
  },
  BookingSignature: {
    path: '/Booking/Signature',
    displayTitle: 'Signature',
  },
  TrackingStatus: {
    path: '/Tracking/Status',
    displayTitle: 'Tracking Status',
  },
  AccountNotConfirm: {
    path: '/account/AccountNotConfirm',
    displayTitle: 'Account not confirm',
  },
  HomePage: {
    path: '/',
    displayTitle: 'Home',
  },
  Service: {
    path: '/service',
    displayTitle: 'Our Service',
  },
  ContactUs: {
    path: '/contactus',
    displayTitle: 'Contact us',
  },
  AboutUs: {
    path: '/aboutus',
    displayTitle: 'About us',
  },
  Perks: {
    path: '/Booking/Perks',
    displayTitle: 'Perks',
  },
  Referral: {
    path: '/Referral',
    displayTitle: 'Referral',
  },
  TermsAndCondition: {
    path: '/termsandcondition',
    displayTitle: 'Terms And Condition',
  },
  TermsAndConditionRewards: {
    path: '/termsandconditionrewards',
    displayTitle: 'Terms And Condition Rewards',
  },
  TermsAndConditions: {
    path: '/termsandconditions',
    displayTitle: 'Terms And Condition',
  },
  PrivacyNotice: {
    path: '/privacynotice',
    displayTitle: 'Privacy notice',
  },
  Balikbayanbox: {
    path: '/balikbayanbox',
    displayTitle: 'Our box design and specifications',
  },
  FAQs: {
    path: '/faqs',
    displayTitle: 'Frequently asked questions',
  },
  OrderBox: {
    path: '/orderbox',
    displayTitle: 'Order a box',
  },
  OrderABoxConfirmation: {
    path: '/orderboxconfirmation',
    displayTitle: 'Order A Box Confirmation',
  },
  SenderListingOrderBox: {
    path: '/senderlistingorderbox',
    displayTitle: 'Sender Listing Order Box',
  },
  SummaryOrderBox: {
    path: '/Transaction/summaryorderbox',
    displayTitle: 'Summary Order Box',
  },
  CommonSender: {
    path: '/common/sender',
    displayTitle: 'Common sender',
  },
  CommonAddress: {
    path: '/common/senderaddress',
    displayTitle: 'Common address',
  },
  CommonReceiver: {
    path: '/common/receiver',
    displayTitle: 'Common receiver',
  },
  UnderConstruction: {
    path: '/UnderConstruction',
    displayTitle: 'UnderConstruction',
  },
  OrderEmtpyBox: {
    path: '/orderEmtpyBox',
    displayTitle: 'KFS Order Box',
  },
  OrderABoxSenderListing: {
    path: '/orderaboxsenderlisting',
    displayTitle: 'OrderABoxSenderListing',
  },
  OrderBoxAddress: {
    path: '/orderboxadddress',
    displayTitle: 'OrderBoxAddress',
  },
  SenderListingBooking: {
    path: '/senderlistingbooking',
    displayTitle: 'senderlistingbooking',
  },
  ReceiverListingBooking: {
    path: '/receiverlistingbooking',
    displayTitle: 'receiverlistingbooking',
  },

  ContainerLoading: {
    path: '/container/loading',
    displayTitle: 'Container loading',
  },
  ContainerProfile: {
    path: '/container/profile',
    displayTitle: 'Container profile',
  },
  ContainerListing: {
    path: '/container/listing',
    displayTitle: 'Container listing',
  },
  KFSStorePartnerSyd: {
    path: '/partner/sydney',
    displayTitle: 'KFS store partners',
  },
  KFSStorePartnerMel: {
    path: '/partner/melbourne',
    displayTitle: 'KFS store partners',
  },
  PricingMel: {
    path: '/pricing/mel',
    displayTitle: 'Pricing',
  },
  PricingSyd: {
    path: '/pricing/syd',
    displayTitle: 'Pricing',
  },
  DeliveryAreaRate: {
    path: '/deliveryarearate',
    displayTitle: 'Delivery Area Rate',
  },
  Delivered: {
    path: '/deliver',
    displayTitle: 'Delivery barcode',
  },

  InterStateLoading: {
    path: '/interstate/loading',
    displayTitle: 'Inter State loading',
  },
  InterStateProfile: {
    path: '/interstate/profile',
    displayTitle: 'Inter State profile',
  },
  InterStateListing: {
    path: '/interstate/listing',
    displayTitle: 'Inter State listing',
  },
  PackingList: {
    path: '/packinglist',
    displayTitle: 'Packing List',
  },
  StoresIncentive: {
    path: '/store/incentives',
    displayTitle: 'Stores Incentive',
  },
  KFSStorePartnerBendigo: {
    path: '/partner/bendigo',
    displayTitle: 'KFS store partners',
  },
  KFSStorePartnerWarnambool: {
    path: '/partner/warrnambool',
    displayTitle: 'KFS store partners',
  },
  TrackingManagement: {
    path: '/tracking/management',
    displayTitle: 'Tracking management',
  },
};

export const ROUTES: RouteConfig[] = [
  {
    path: PATHS.Home.path,
    key: PATHS.Home.path,
    exact: true,
    component: () => <Home />,
  },
  {
    path: PATHS.Login.path,
    key: PATHS.Login.path,
    exact: true,
    component: () => <Login />,
    displayTitle: PATHS.Login.displayTitle,
  },
  {
    path: PATHS.Sender.path,
    key: PATHS.Sender.path,
    exact: true,
    component: () => <Sender />,
    displayTitle: PATHS.Sender.displayTitle,
  },
  {
    path: PATHS.SenderAddress.path,
    key: PATHS.SenderAddress.path,
    exact: true,
    component: () => <SenderAddress />,
    displayTitle: PATHS.SenderAddress.displayTitle,
  },
  {
    path: PATHS.Receiver.path,
    key: PATHS.Receiver.path,
    exact: true,
    component: () => <Receiver />,
  },
  {
    path: PATHS.BoxContent.path,
    key: PATHS.BoxContent.path,
    exact: true,
    component: () => <BoxContent />,
  },
  {
    path: PATHS.BoxDetails.path,
    key: PATHS.BoxDetails.path,
    exact: true,
    component: () => <BoxDetails />,
  },
  {
    path: PATHS.Summary.path,
    key: PATHS.Summary.path,
    exact: true,
    component: () => <Summary />,
  },
  {
    path: PATHS.Confirmation.path,
    key: PATHS.Confirmation.path,
    exact: true,
    component: () => <Confirmation />,
  },
  {
    path: PATHS.BookingListing.path,
    key: PATHS.BookingListing.path,
    exact: true,
    component: () => <Listing />,
  },
  {
    path: PATHS.Signup.path,
    key: PATHS.Signup.path,
    exact: true,
    component: () => <SignUp />,
  },
  {
    path: PATHS.ResetPassword.path,
    key: PATHS.ResetPassword.path,
    exact: true,
    component: () => <ResetPassword />,
  },
  {
    path: PATHS.ResetConfirmation.path,
    key: PATHS.ResetConfirmation.path,
    exact: true,
    component: () => <PasswordReset />,
  },
  {
    path: PATHS.ChangePassword.path,
    key: PATHS.ChangePassword.path,
    exact: true,
    component: () => <ChangePassword />,
  },
  {
    path: PATHS.SenderListing.path,
    key: PATHS.SenderListing.path,
    exact: true,
    component: () => <SenderListing />,
  },
  {
    path: PATHS.Tracking.path,
    key: PATHS.Tracking.path,
    exact: true,
    component: () => <Tracking />,
  },
  {
    path: PATHS.SenderDetails.path,
    key: PATHS.SenderDetails.path,
    exact: true,
    component: () => <SenderDetails />,
  },
  {
    path: PATHS.Declaration.path,
    key: PATHS.Declaration.path,
    exact: true,
    component: () => <Declaration />,
  },
  {
    path: PATHS.SenderAdd.path,
    key: PATHS.SenderAdd.path,
    exact: true,
    component: () => <SenderAdd />,
  },
  {
    path: PATHS.ReceiverListing.path,
    key: PATHS.ReceiverListing.path,
    exact: true,
    component: () => <ReceiverListing />,
  },
  {
    path: PATHS.ReceiverDetails.path,
    key: PATHS.ReceiverDetails.path,
    exact: true,
    component: () => <ReceiverDetails />,
  },
  {
    path: PATHS.Transaction.path,
    key: PATHS.Transaction.path,
    exact: true,
    component: () => <Transaction />,
  },
  {
    path: PATHS.Invoice.path,
    key: PATHS.Invoice.path,
    exact: true,
    component: () => <Invoice />,
  },
  {
    path: PATHS.Barcode.path,
    key: PATHS.Barcode.path,
    exact: true,
    component: () => <Barcode />,
  },
  {
    path: PATHS.BarcodeGenerate.path,
    key: PATHS.BarcodeGenerate.path,
    exact: true,
    component: () => <GenerateBarcode />,
  },
  {
    path: PATHS.TransactionSummary.path,
    key: PATHS.TransactionSummary.path,
    exact: true,
    component: () => <SummaryTransaction />,
  },
  {
    path: PATHS.BookingSignature.path,
    key: PATHS.BookingSignature.path,
    exact: true,
    component: () => <BookingSignature />,
  },
  {
    path: PATHS.TrackingStatus.path,
    key: PATHS.TrackingStatus.path,
    exact: true,
    component: () => <TrackingStatus />,
  },
  {
    path: PATHS.AccountNotConfirm.path,
    key: PATHS.AccountNotConfirm.path,
    exact: true,
    component: () => <AccountNotConfirm />,
  },
  {
    path: PATHS.HomePage.path,
    key: PATHS.HomePage.path,
    exact: true,
    component: () => <Homepage />,
  },
  {
    path: PATHS.Service.path,
    key: PATHS.Service.path,
    exact: true,
    component: () => <Service />,
  },
  {
    path: PATHS.ContactUs.path,
    key: PATHS.ContactUs.path,
    exact: true,
    component: () => <ContactUs />,
  },
  {
    path: PATHS.AboutUs.path,
    key: PATHS.AboutUs.path,
    exact: true,
    component: () => <AboutUs />,
  },
  {
    path: PATHS.ConfirmAccount.path,
    key: PATHS.ConfirmAccount.path,
    exact: true,
    component: () => <ConfirmAccount />,
  },
  {
    path: PATHS.Perks.path,
    key: PATHS.Perks.path,
    exact: true,
    component: () => <Perks />,
  },
  {
    path: PATHS.Referral.path,
    key: PATHS.Referral.path,
    exact: true,
    component: () => <Referral />,
  },
  {
    path: PATHS.TermsAndCondition.path,
    key: PATHS.TermsAndCondition.path,
    exact: true,
    component: () => <TermsAndCondition />,
  },
  {
    path: PATHS.TermsAndConditionRewards.path,
    key: PATHS.TermsAndConditionRewards.path,
    exact: true,
    component: () => <TermsAndConditionRewards />,
  },
  {
    path: PATHS.PrivacyNotice.path,
    key: PATHS.PrivacyNotice.path,
    exact: true,
    component: () => <PrivacyNotice />,
  },
  {
    path: PATHS.Balikbayanbox.path,
    key: PATHS.Balikbayanbox.path,
    exact: true,
    component: () => <Balikbayanbox />,
  },
  {
    path: PATHS.FAQs.path,
    key: PATHS.FAQs.path,
    exact: true,
    component: () => <FAQs />,
  },
  {
    path: PATHS.OrderBox.path,
    key: PATHS.OrderBox.path,
    exact: true,
    component: () => <OrderBox />,
  },
  {
    path: PATHS.OrderABoxConfirmation.path,
    key: PATHS.OrderABoxConfirmation.path,
    exact: true,
    component: () => <OrderABoxConfirmation />,
  },
  {
    path: PATHS.SenderListingOrderBox.path,
    key: PATHS.SenderListingOrderBox.path,
    exact: true,
    component: () => <SenderListingOrderBox />,
  },
  {
    path: PATHS.SummaryOrderBox.path,
    key: PATHS.SummaryOrderBox.path,
    exact: true,
    component: () => <SummaryOrderBox />,
  },
  {
    path: PATHS.CommonSender.path,
    key: PATHS.CommonSender.path,
    exact: true,
    component: () => <SenderCommon onBack="" onNext="" />,
  },
  {
    path: PATHS.CommonAddress.path,
    key: PATHS.CommonAddress.path,
    exact: true,
    component: () => <SenderAddressCommon onBack="" onNext="" />,
  },
  {
    path: PATHS.CommonReceiver.path,
    key: PATHS.CommonReceiver.path,
    exact: true,
    component: () => <ReceiverCommon />,
  },
  {
    path: PATHS.UnderConstruction.path,
    key: PATHS.UnderConstruction.path,
    exact: true,
    component: () => <UnderConstructionPage />,
  },
  {
    path: PATHS.OrderEmtpyBox.path,
    key: PATHS.OrderEmtpyBox.path,
    exact: true,
    component: () => <OrderBoxSender />,
  },
  {
    path: PATHS.OrderBoxAddress.path,
    key: PATHS.OrderBoxAddress.path,
    exact: true,
    component: () => <OrderBoxAddress />,
  },
  {
    path: PATHS.SenderListingBooking.path,
    key: PATHS.SenderListingBooking.path,
    exact: true,
    component: () => <SenderListingBooking />,
  },
  {
    path: PATHS.ReceiverListingBooking.path,
    key: PATHS.ReceiverListingBooking.path,
    exact: true,
    component: () => <ReceiverListingBooking />,
  },
  {
    path: PATHS.ContainerLoading.path,
    key: PATHS.ContainerLoading.path,
    exact: true,
    component: () => <ContainerLoading />,
  },
  {
    path: PATHS.ContainerProfile.path,
    key: PATHS.ContainerProfile.path,
    exact: true,
    component: () => <ContainerProfile />,
  },
  {
    path: PATHS.ContainerListing.path,
    key: PATHS.ContainerListing.path,
    exact: true,
    component: () => <ContainerListing />,
  },
  {
    path: PATHS.KFSStorePartnerMel.path,
    key: PATHS.KFSStorePartnerMel.path,
    exact: true,
    component: () => <StorePartnerMel />,
  },
  {
    path: PATHS.KFSStorePartnerSyd.path,
    key: PATHS.KFSStorePartnerSyd.path,
    exact: true,
    component: () => <StorePartnerSyd />,
  },
  {
    path: PATHS.PricingMel.path,
    key: PATHS.PricingMel.path,
    exact: true,
    component: () => <PricingMel />,
  },
  {
    path: PATHS.PricingSyd.path,
    key: PATHS.PricingSyd.path,
    exact: true,
    component: () => <PricingSyd />,
  },
  {
    path: PATHS.DeliveryAreaRate.path,
    key: PATHS.DeliveryAreaRate.path,
    exact: true,
    component: () => <DeliveryAreaRate />,
  },
  {
    path: PATHS.Delivered.path,
    key: PATHS.Delivered.path,
    exact: true,
    component: () => <Delivered />,
  },
  {
    path: PATHS.InterStateListing.path,
    key: PATHS.InterStateListing.path,
    exact: true,
    component: () => <InterStateListing />,
  },
  {
    path: PATHS.InterStateProfile.path,
    key: PATHS.InterStateProfile.path,
    exact: true,
    component: () => <InterStateProfile />,
  },
  {
    path: PATHS.InterStateLoading.path,
    key: PATHS.InterStateLoading.path,
    exact: true,
    component: () => <InterStateLoading />,
  },
  {
    path: PATHS.PackingList.path,
    key: PATHS.PackingList.path,
    exact: true,
    component: () => <PackingList />,
  },
  {
    path: PATHS.StoresIncentive.path,
    key: PATHS.StoresIncentive.path,
    exact: true,
    component: () => <StoresIncentive />,
  },
  {
    path: PATHS.KFSStorePartnerBendigo.path,
    key: PATHS.KFSStorePartnerBendigo.path,
    exact: true,
    component: () => <StorePartnerBendigo />,
  },
  {
    path: PATHS.KFSStorePartnerWarnambool.path,
    key: PATHS.KFSStorePartnerWarnambool.path,
    exact: true,
    component: () => <StorePartnerWarnambool />,
  },
  {
    path: PATHS.TrackingManagement.path,
    key: PATHS.TrackingManagement.path,
    exact: true,
    component: () => <TrackingManagement />,
  },
  {
    path: PATHS.TermsAndConditions.path,
    key: PATHS.TermsAndConditions.path,
    exact: true,
    component: () => <TermsAndConditions />,
  },
];
