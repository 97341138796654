/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * we are using rdtk's createSlice which will internally invoke createAction,
 * thus we will generate unused var
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'store/rootTypes';
import { clearState } from 'store/actions';
import { DeliveryAreaRate, DeliveryAreaRateResponse } from 'common/models/DeliveryAreaRateTypes';
import { CodeSets } from 'common/models/CodeSetsTypes';

interface ICommonState {
  loadCodeSetItemStatus: ApiStatus;
  GlobalSenderUserId?: string;
  GlobalSenderId?: string;
  GlobalTransactionId?: string;
  GlobalReceiverUserId?: string;
  GlobalReceiverId?: string;
  loadSessionTimeoutStatus: ApiStatus;
  SessionTimeout: number;
  PromptBeforeIdle: number;
  DeliveryAreaRate?: string[];
  DeliveryAreaRateLocation?: DeliveryAreaRateResponse[];
  CodeSets?: CodeSets[];
}
const name = 'common';

const initialState: ICommonState = {
  loadCodeSetItemStatus: ApiStatus.idle,

  loadSessionTimeoutStatus: ApiStatus.idle,
  SessionTimeout: 20,
  PromptBeforeIdle: 2,
};

export const commonSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadCodeSetItems: (state) => {
      state.loadCodeSetItemStatus = ApiStatus.pending;
    },
    SetGlobalSenderUserId: (state, action: PayloadAction<string>) => {
      state.GlobalSenderUserId = action.payload;
    },
    SetGlobalSenderId: (state, action: PayloadAction<string>) => {
      state.GlobalSenderId = action.payload;
    },
    SetGlobalReceiverUserId: (state, action: PayloadAction<string>) => {
      state.GlobalReceiverUserId = action.payload;
    },
    SetGlobalReceiverId: (state, action: PayloadAction<string>) => {
      state.GlobalReceiverId = action.payload;
    },
    SetGlobalTransactionId: (state, action: PayloadAction<string>) => {
      state.GlobalTransactionId = action.payload;
    },
    SetDeliveryAreaRate: (state, action: PayloadAction<string[]>) => {
      state.DeliveryAreaRate = action.payload;
    },
    SetDeliveryAreaRateLocation: (state, action: PayloadAction<DeliveryAreaRateResponse[] | undefined>) => {
      state.DeliveryAreaRateLocation = action.payload;
    },
    SetCodeSets: (state, action: PayloadAction<CodeSets[]>) => {
      state.CodeSets = action.payload;
    },
    ResetGlobalState: (state) => {
      state.GlobalSenderUserId = undefined;
      state.GlobalSenderId = undefined;
      state.GlobalReceiverUserId = undefined;
      state.GlobalReceiverId = undefined;
      state.GlobalTransactionId = undefined;
      state.DeliveryAreaRate = undefined;
      state.DeliveryAreaRateLocation = undefined;
    },
    loadCodeSetItemsReset: (state) => {
      state.loadCodeSetItemStatus = ApiStatus.idle;
    },
    loadCodeSetItemsRejected: (state, action: PayloadAction<string>) => {
      state.loadCodeSetItemStatus = ApiStatus.failed;
      console.error(action.payload);
    },
    loadSessionTimeout: (state) => {
      state.loadSessionTimeoutStatus = ApiStatus.pending;
    },

    loadSessionTimeoutSucceeded: (state, action: PayloadAction<number>) => {
      state.SessionTimeout = action.payload;
      state.loadSessionTimeoutStatus = ApiStatus.succeeded;
    },
    loadSessionTimeoutRejected: (state, action: PayloadAction<string>) => {
      state.loadSessionTimeoutStatus = ApiStatus.failed;
      console.error(action.payload);
    },

    //is called together with sessiontimeout
    loadPromptBeforeIdle: (state, action: PayloadAction<number>) => {
      state.PromptBeforeIdle = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearState, (state, action) => {
      return initialState;
    });
  },
});

export const { actions: commonAction } = commonSlice;

export default commonSlice.reducer;
