import { Alert, Button, Col, Form, message, Row } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { ConfirmUser } from '../business/AccountBusiness';
import { Link } from 'react-router-dom';
import { PATHS } from '@routing/routes';

export const ConfirmAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onFinish = () => {
    history.push('/booking/summary');
  };
  useEffect(() => {
    const fetchData = async () => {
      const token = new URLSearchParams(location.search).get('token');

      if (token) {
        await dispatch(ConfirmUser(token));
      }
    };
    fetchData();
  }, [dispatch, location.search]);

  return (
    <div className="App">
      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <br />
            <h2>Account confirmed!</h2>
            <label>You can now log in to your account.</label>
            <br />
            <label>Thank you for confirming your email address.</label>
            <br />
            <br />
            <Row justify="center" align="middle">
              <Col xs={24} sm={18} md={12} lg={10} xl={12}>
                <Link to={PATHS.Login.path} className="normal-font">
                  Back to Log in
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
