import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OperationStatus } from 'store/rootTypes';

const name = 'Incentive';

interface IIncentivetate {
  submitStatus: OperationStatus;
}

const initialState: IIncentivetate = {
  submitStatus: OperationStatus.idle,
};

export const IncentiveSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    submitStatus: (state) => handlePendingSubmitAction(state),
    submitStatusRejected: (state, action: PayloadAction<string>) => handleSubmitRejection(state, action),
    submitStatusSucceeded: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    submitStatusSucceededNoPayload: (state) => {
      state.submitStatus = OperationStatus.succeeded;
    },
    resetSubmitStatus: (state) => {
      state.submitStatus = OperationStatus.idle;
    },
  },
});

const handlePendingSubmitAction = (state) => {
  state.submitStatus = OperationStatus.pending;
};

const handleSubmitRejection = (state, action: PayloadAction<string>) => {
  state.submitStatus = OperationStatus.failed;
  console.error(action.payload);
};

export const { actions: IncentiveActions } = IncentiveSlice;

export default IncentiveSlice.reducer;
