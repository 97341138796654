// === Counter State Selectors ===
//
// Selector functions that accept Redux state as an argument and return data derived
// from that state. Selectors can provide performance optimizations (e.g. using the
// "useSelector" hook) and also encapsulate access to the global state tree.
//
// Use these functions to avoid having to do:
//   state.counter.value ...
// calls everywhere in the code
//

import { RootState } from '../../../store/rootTypes';

export const commonSelector = (state: RootState) => state.common;
