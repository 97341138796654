import { Card, Col, Row } from 'antd';
import '@features/Webpage/homepage.css';

import { WebsiteFooter } from './Footer';
import {
  KFS_Luzon_Mel,
  KFS_Luzon_Syd,
  KFS_Min_Mel,
  KFS_Min_Syd,
  KFS_Vis_Mel,
  KFS_Vis_Syd,
  kfsmnlsyd,
  mnlMel,
} from 'common/images';
import { useHistory } from 'react-router';
import { PATHS } from '@routing/routes';
export const PricingSyd = () => {
  const history = useHistory();
  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextAboutUs">Our rates</p>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col sm={24} className="">
          <div className="">
            <a className="normal-font dInlineBlock" onClick={() => history.push(PATHS.PricingMel.path)}>
              <h3 className="normalfontweight melsydlbl"> Melbourne </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock underline">
              <h3 className="normalfontweight melsydlbl"> Sydney</h3>
            </a>
          </div>
        </Col>
      </Row>

      <div className="sydneyDiv">
        <p className="mtb listedprice">
          <i className="textColor ">
            * The prices listed below apply to Metro Sydney. For pricing in other areas, please get in touch with our
            customer service, and we will furnish you with accurate cost details.
          </i>
        </p>
        <Row gutter={24}>
          <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
            <Card
              title={
                <div>
                  Biyaheng Metro Manila <br /> $105.00
                </div>
              }
              cover={<img alt="example" src={kfsmnlsyd} className="serviceImg" />}
            >
              <div className="center-text">
                <h3 className="font14px"> 5 to 6 Weeks</h3>
              </div>
            </Card>
          </Col>
          <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
            <Card
              title={
                <div>
                  Biyaheng Luzon <br /> $115.00
                </div>
              }
              cover={<img alt="example" src={KFS_Luzon_Syd} className="serviceImg" />}
            >
              <div className="center-text">
                <h3 className="font14px"> 7 to 8 Weeks</h3>
              </div>
            </Card>
          </Col>
          <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
            <Card
              title={
                <div>
                  Biyaheng Visayas <br /> $135.00
                </div>
              }
              cover={<img alt="example" src={KFS_Vis_Syd} className="serviceImg" />}
            >
              <div className="center-text">
                <h3 className="font14px"> 8 to 9 Weeks</h3>
              </div>
            </Card>
          </Col>
          <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
            <Card
              className="cardService"
              title={
                <div>
                  Biyaheng Mindanao <br /> $145.00
                </div>
              }
              cover={<img alt="example" src={KFS_Min_Syd} className="serviceImg" />}
            >
              <div className="center-text">
                <h3 className="font14px"> 8 to 9 Weeks</h3>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
