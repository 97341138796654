import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from '../../../store/rootTypes';
import { clearState } from 'store/actions';

interface ILoginState {
  userInitials?: string;
  status: ApiStatus;
}

const name = 'login';

const handleRejection = (state, action: PayloadAction<string>) => {
  state.status = ApiStatus.failed;
  console.error(action.payload);
};

const handlePendingAction = (state) => {
  state.status = ApiStatus.pending;
};

const initialState: ILoginState = {
  status: ApiStatus.idle,
  userInitials: Math.random().toString(36).slice(3, 5).toLocaleUpperCase(),
};

export const loginSlice = createSlice({
  name,
  initialState,
  reducers: {
    //Load Patient reducers
    load: (state) => handlePendingAction(state),
    loadRejected: (state, action: PayloadAction<string>) => handleRejection(state, action),
    update: (state) => handlePendingAction(state),
    updateRejected: (state, action: PayloadAction<string>) => handleRejection(state, action),
    loadUserInitials: (state, action: PayloadAction<string>) => {
      state.userInitials = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearState, (state, action) => {
      return initialState;
    });
  },
});

export const { actions: loginAction } = loginSlice;

export default loginSlice.reducer;
