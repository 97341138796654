import { Link, useHistory } from 'react-router-dom';
import { Drawer, Menu } from 'antd';
import { Constants } from 'common/constants';
import { PATHS } from 'common/route/routes';
import { GetUUID } from 'common/utils/utility';
import { ClearCache } from '@features/Login';
import type { MenuProps } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './CustomMenu.module.less';
import { BookingActions } from '@features/Booking';
import { SenderDetailsActions } from '@features/SenderDetails';
import { BarcodeActions } from '@features/Barcode';
import { ReceiverActions } from '@features/Receiver';
import { TransactionActions } from '@features/Transaction';
import { TrackingActions } from '@features/Tracking';
import { FaUserCircle } from 'react-icons/fa';
import { useState } from 'react';
export const CustomMenuHomepage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleClick = (e) => {
    setDrawerVisible(false);
    // perform different actions based on e.key or e.item
    switch (e.key) {
      case Constants.LOGOUT_LABEL:
        // LogoutRedirect();
        ClearCache();
        dispatch(BookingActions.resetState());
        dispatch(SenderDetailsActions.resetState());
        dispatch(ReceiverActions.resetState());
        dispatch(BarcodeActions.resetState());
        dispatch(TransactionActions.resetState());
        dispatch(TrackingActions.resetState());
        history.push('/homepage');
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: <Link to={PATHS.HomePage.path}>Home</Link>,
      key: GetUUID(),
    },
    {
      label: <Link to={PATHS.AboutUs.path}>About us</Link>,
      key: GetUUID(),
    },
    {
      label: <Link to={PATHS.ContactUs.path}>Contact us</Link>,
      key: GetUUID(),
    },
    {
      label: <Link to={PATHS.FAQs.path}>FAQs</Link>,
      key: GetUUID(),
    },
    {
      label: (
        <>
          <FaUserCircle className="homepageIconBookaPickup" />
          <Link to={PATHS.Login.path}>Book online</Link>
        </>
      ),
      key: GetUUID(),
    },
  ];

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };
  return (
    <>
      <div className="webPageSmallMenu">
        <div className="hamburger-menu-icon" onClick={showDrawer}>
          ☰
        </div>

        {/* Drawer (hamburger menu) for small screens */}
        <Drawer title="" placement="left" onClose={onClose} visible={drawerVisible}>
          <Menu onClick={handleClick} mode="vertical" items={items} />
        </Drawer>
      </div>

      <div className="webPageBigMenu">
        <Menu
          className={`justifyContentFlexEnd ${styles.normalFont} ${styles.borderBottomUnset}`}
          onClick={handleClick}
          mode="horizontal"
          items={items}
        />
      </div>
    </>
  );
};
