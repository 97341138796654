import React, { useState } from 'react';
import { Alert, Breadcrumb, Button, Col, Form, Input, Menu, message, Row, Select, Space, Tag } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Constants } from 'common/constants';
import { AccountSelector } from '../store/AccountSelector';
import { ChangePasswordViewModel } from 'common/models/UserTypes';
import { ChangePasswordBusiness } from '../business/AccountBusiness';
import '@features/Account/Account.css';
export const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const accountState = useSelector(AccountSelector);
  const [message, setMessage] = useState<any>('');
  const [isSuccess, setIsSuccess] = useState(false);
  const onFinish = async (values: ChangePasswordViewModel) => {
    setMessage('');
    setIsSuccess(false);
    const data = await dispatch(ChangePasswordBusiness(values));
    if (data && data != undefined) {
      setMessage(data);
      setIsSuccess(false);
    } else {
      setMessage('');
      setIsSuccess(true);
      form.resetFields();
    }
  };
  const [form] = Form.useForm();

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject('This field is required');
    }

    if (!Constants.PASSWORD_REGEX.test(value)) {
      const informationMessage = (
        <div>
          <p className="pHeader">Passwords should be a minimum of 8 characters long and contain a mix of:</p>
          <ul className="pBottom">
            <li>numbers</li>
            <li>uppercase and lowercase letters</li>
            <li>special characters {Constants.ERROR_PASSWORD_ssFORMAT}</li>
          </ul>
        </div>
      );
      return Promise.reject(<Alert description={informationMessage} type="error" />);
    }

    return Promise.resolve();
  };
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Change Password</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <br />
            <Form.Item
              label="Old password"
              name="OldPassword"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input.Password type="password" size="large" maxLength={20} />
            </Form.Item>
            <Form.Item label="New password" name="NewPassword" rules={[{ validator: validatePassword }]}>
              <Input.Password type="password" size="large" maxLength={20} />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="ConfirmPassword"
              dependencies={['Password']}
              rules={[
                { required: true, message: Constants.REQUIRED_FIELD },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('NewPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The new password and confirm password do not match.');
                  },
                }),
              ]}
            >
              <Input.Password type="password" size="large" maxLength={150} />
            </Form.Item>

            <div>
              {isSuccess && <Alert message="Password changed successfully." type="success" showIcon />}

              {message && <Alert message={message} type="error" showIcon />}

              <br />
              <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push('/home')}>
                Close
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
